import { buildCollection, buildSchema } from '@camberi/firecms';
import 'firebase/functions';

const schema = buildSchema({
	name: 'Document',
	properties: {
		name: {
			dataType: 'string',
			title: 'Name',
			validation: { required: true },
		},
		url: {
			config: {
				url: true,
			},
			dataType: 'string',
			title: 'Url',
		},
		file: {
			dataType: 'array',
			of: {
				config: {
					storageMeta: {
						acceptedFiles: ['application/*'],
						metadata: {
							cacheControl: 'max-age=1000000',
						},
						storagePath: 'documents',
					},
				},
				dataType: 'string',
			},
			title: 'Files',
		},
		created_at: {
			dataType: 'timestamp',
			disabled: true,
			title: 'Created at',
		},
		updated_at: {
			dataType: 'timestamp',
			disabled: true,
			title: 'Updated at',
		},
	},
});

const collection = buildCollection<typeof schema>({
	description: 'Documents',
	exportable: false,
	group: 'Employees',
	name: 'Documents',
	pagination: true,
	permissions: ({ authController, user }) => ({
		create: authController.extra.roles.includes('superadmin'),
		delete: authController.extra.roles.includes('superadmin'),
		edit: authController.extra.roles.includes('superadmin'),
	}),
	relativePath: 'documents',
	schema: schema,
});

export default collection;
