import { useAuthController } from '@camberi/firecms';
import firebase from 'firebase/app';
import 'firebase/functions';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { Settings } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

const ResultActions = ({ id }: { id: string }) => {
	const authController = useAuthController();
	const [menuToggle, setMenuToggle] = useState<any | null>(null);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const handleClick = (event: any) => {
		setMenuToggle(event.currentTarget);
		event.stopPropagation();
	};

	const handleClose = () => {
		setMenuToggle(null);
	};

	const onClickNewCovidBevis = async (event: React.MouseEvent) => {
		setMenuToggle(null);

		try {
			const snackbarKey = enqueueSnackbar(
				'Adding creation of covidbevis to notification queue, please wait..',
				{
					persist: true,
					variant: 'info',
				}
			);

			const createCertificate = firebase
				.app()
				.functions('europe-west1')
				.httpsCallable('result-createCertificate');
			const result = await createCertificate({ id: id });

			closeSnackbar(snackbarKey);

			const status = (result as any).data.status;
			if (status === 'ok') {
				enqueueSnackbar('Covidbevis added to notification queue..', {
					autoHideDuration: 10000,
					variant: 'success',
				});
			} else {
				const codes = (result as any).data.data.codes;
				let message = (result as any).data.data.message;
				if (!message) {
					message = `Unable to add covidbevis to notification queue: ${codes.join(
						', '
					)}`;
				}
				enqueueSnackbar(message, {
					autoHideDuration: 10000,
					variant: 'error',
				});
			}
		} catch (error) {
			console.log(error);
			enqueueSnackbar('Unable to add covidbevis to notification queue.', {
				autoHideDuration: 10000,
				variant: 'error',
			});
		}
	};

	const onClickNotification = async (event: React.MouseEvent) => {
		setMenuToggle(null);

		try {
			const snackbarKey = enqueueSnackbar(
				'Adding notification(s) to queue, please wait..',
				{
					persist: true,
					variant: 'info',
				}
			);

			const resultNotify = firebase
				.app()
				.functions('europe-west1')
				.httpsCallable('result-notify');
			const result = await resultNotify({ id: id });

			closeSnackbar(snackbarKey);

			const status = (result as any).data.status;
			if (status === 'ok') {
				enqueueSnackbar('Notification(s) added to queue.', {
					autoHideDuration: 10000,
					variant: 'success',
				});
			} else {
				const codes = (result as any).data.data.codes;
				let message = (result as any).data.data.message;
				if (!message) {
					message = `Unable to add notification(s) to queue: ${codes.join(
						', '
					)}`;
				}

				enqueueSnackbar(message, {
					autoHideDuration: 10000,
					variant: 'error',
				});
			}
		} catch (error) {
			console.log(error);
			enqueueSnackbar('Unable to add notification(s) to queue.', {
				autoHideDuration: 10000,
				variant: 'error',
			});
		}
	};

	const onClickResultPage = async (event: React.MouseEvent) => {
		setMenuToggle(null);
		window.open(
			`https://www.testmottagningen.se/resultat/?id=${id}&test=true`,
			'_blank'
		);
	};

	const onClickResultPdf = async (event: React.MouseEvent) => {
		setMenuToggle(null);
		authController?.loggedUser?.getIdToken().then(function (idToken: string) {
			window.open(
				`https://europe-west1-testmottagningen.cloudfunctions.net/result-pdf?id=${id}&test=true&token=${idToken}`,
				'_blank'
			);
		});
	};

	const onClickCovidbevisPdf = async (event: React.MouseEvent) => {
		setMenuToggle(null);
		authController?.loggedUser?.getIdToken().then(function (idToken: string) {
			window.open(
				`https://europe-west1-testmottagningen.cloudfunctions.net/result-pdf?id=${id}&test=true&type=covidbevis&token=${idToken}`,
				'_blank'
			);
		});
	};

	const onClickResultJapanPdf = async (event: React.MouseEvent) => {
		setMenuToggle(null);
		authController?.loggedUser?.getIdToken().then(function (idToken: string) {
			window.open(
				`https://europe-west1-testmottagningen.cloudfunctions.net/result-pdf?id=${id}&test=true&type=japan&token=${idToken}`,
				'_blank'
			);
		});
	};

	const onClickRequestAccess = async (event: React.MouseEvent) => {
		let url =
			'https://europe-west1-testmottagningen.cloudfunctions.net/result-requestAccess';
		setMenuToggle(null);

		try {
			const snackbarKey = enqueueSnackbar(
				'Adding new access code to notification queue, please wait..',
				{
					persist: true,
					variant: 'info',
				}
			);

			const requestAccess = await fetch(`${url}?id=${id}`);
			const result = await requestAccess.json();

			closeSnackbar(snackbarKey);

			const status = (result as any).status;
			if (status === 'ok') {
				enqueueSnackbar('New access code is added to notification queue..', {
					autoHideDuration: 10000,
					variant: 'success',
				});
			} else {
				const codes = (result as any).data.codes;
				let message = (result as any).data.message;
				if (!message) {
					message = `Unable to create a new access code: ${codes.join(', ')}`;
				}
				enqueueSnackbar(message, {
					autoHideDuration: 10000,
					variant: 'error',
				});
			}
		} catch (error) {
			console.log(error);
			enqueueSnackbar('Unable to create a new access code.', {
				autoHideDuration: 10000,
				variant: 'error',
			});
		}
	};

	const onClickReport = async (event: React.MouseEvent) => {
		setMenuToggle(null);
		window.open(
			`https://api.testmottagningen.se/result-pdfReport?id=${id}`,
			'_blank'
		);
	};

	return (
		<>
			<Button
				aria-controls="simple-menu"
				aria-haspopup="true"
				onClick={handleClick}
			>
				<Settings />
			</Button>
			<Menu
				anchorEl={menuToggle}
				elevation={2}
				id="simple-menu"
				keepMounted
				open={Boolean(menuToggle)}
				onClose={handleClose}
			>
				<MenuItem onClick={onClickResultPdf}>Open result pdf</MenuItem>
				<MenuItem onClick={onClickCovidbevisPdf}>Open covidbevis pdf</MenuItem>
				<MenuItem onClick={onClickResultJapanPdf}>
					Open japanese result pdf
				</MenuItem>
				<MenuItem>-------------------------------</MenuItem>
				<MenuItem onClick={onClickResultPage}>Open result page</MenuItem>
				<MenuItem>-------------------------------</MenuItem>
				<MenuItem onClick={onClickNotification}>
					Send email and sms notification
				</MenuItem>
				<MenuItem onClick={onClickNewCovidBevis}>
					Create new covid bevis
				</MenuItem>
				<MenuItem onClick={onClickRequestAccess}>
					Send a new access code with email and sms
				</MenuItem>
				<MenuItem>-------------------------------</MenuItem>
				<MenuItem onClick={onClickReport}>Open sminet report</MenuItem>
			</Menu>
		</>
	);
};

export default ResultActions;
