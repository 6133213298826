import { CMSApp } from '@camberi/firecms';
import { SnackbarProvider } from 'notistack';

import 'typeface-rubik';
import 'typeface-space-mono';

import authentication from './lib/authentication';
import config from './lib/config';
import navigation from './lib/navigation';
import toolbar from './components/toolbar';

export default function App() {
	return (
		<SnackbarProvider>
			<CMSApp
				authentication={authentication}
				dateTimeFormat={config.site.date_time_format}
				firebaseConfig={config.firebase}
				fontFamily={config.site.font_family}
				locale={config.site.locale}
				logo={config.site.logo}
				name={config.site.name}
				navigation={navigation}
				primaryColor={config.site.primary_color}
				secondaryColor={config.site.secondary_color}
				signInOptions={[config.authentication]}
				toolbarExtraWidget={toolbar}
			/>
		</SnackbarProvider>
	);
}
