import { buildCollection, buildSchema } from '@camberi/firecms';

const schema = buildSchema({
	name: 'Support',
	properties: {
		title: {
			dataType: 'string',
			description: 'Only used in admin for easier navigation',
			title: 'Title',
			validation: { required: true },
		},
		translation_key: {
			dataType: 'string',
			title: 'Translation key',
			validation: { required: true },
		},
		created_at: {
			disabled: true,
			title: 'Created at',
			dataType: 'timestamp',
		},
		updated_at: {
			disabled: true,
			title: 'Updated at',
			dataType: 'timestamp',
		},
	},
});

const collection = buildCollection<typeof schema>({
	description: 'Support items',
	group: 'Content',
	name: 'Support',
	permissions: ({ authController, user }) => ({
		create: authController.extra.roles.includes('superadmin'),
		delete: authController.extra.roles.includes('superadmin'),
		edit: authController.extra.roles.includes('superadmin'),
	}),
	properties: ['title', 'translation_key', 'created_at', 'updated_at'],
	relativePath: 'support',
	schema: schema,
});

export default collection;
