import { buildCollection, buildSchema } from '@camberi/firecms';
import { SearchDelegate } from '../lib/search';

const schema = buildSchema({
	name: 'Notification queue',
	properties: {
		archive_result: {
			collectionPath: 'archive_results',
			dataType: 'reference',
			previewProperties: ['patient_id', 'firstname', 'location'],
			title: 'Archive result',
		},
		booking: {
			collectionPath: 'bookings',
			dataType: 'reference',
			previewProperties: ['patient_id', 'firstname', 'location'],
			title: 'Booking',
		},
		data: {
			config: {
				multiline: true,
			},
			dataType: 'string',
			disabled: true,
			title: 'Data',
		},
		destination: {
			config: {
				enumValues: {
					covidbevis: 'Covidbevis',
					email: 'Email',
					sms: 'SMS',
				},
			},
			dataType: 'string',
			description: 'Destination',
			title: 'Destination',
			validation: { required: true },
		},
		external_id: {
			dataType: 'string',
			disabled: true,
			title: 'External id',
		},
		message: {
			dataType: 'string',
			disabled: true,
			title: 'Message',
		},
		result: {
			collectionPath: 'results',
			dataType: 'reference',
			previewProperties: ['patient_id', 'firstname', 'location'],
			title: 'Result',
		},
		status: {
			config: {
				enumValues: {
					error: 'Error',
					pending: 'Pending',
					retry: 'Retry',
					sent: 'Sent',
				},
			},
			dataType: 'string',
			description: 'Status',
			title: 'Status',
			validation: { required: true },
		},
		type: {
			config: {
				enumValues: {
					booking: 'Booking',
					result: 'Result',
					result_access: 'Result access',
				},
			},
			dataType: 'string',
			description: 'Type',
			title: 'Type',
			validation: { required: true },
		},
		created_at: {
			dataType: 'timestamp',
			disabled: true,
			title: 'Created at',
		},
		updated_at: {
			dataType: 'timestamp',
			disabled: true,
			title: 'Updated at',
		},
		verified_at: {
			dataType: 'timestamp',
			disabled: true,
			title: 'Verified at',
		},
	},
});

const collection = buildCollection<typeof schema>({
	description: 'Notification queue',
	group: 'Data',
	initialFilter: {
		status: ['!=', 'sent'],
	},
	name: 'Notification queue',
	permissions: ({ authController, user }) => ({
		create: true,
		delete: true,
		edit: true,
	}),
	properties: [
		'status',
		'type',
		'destination',
		'booking',
		'result',
		'archive_result',
		'message',
		'data',
		'external_id',
		'created_at',
		'updated_at',
		'verified_at',
	],
	relativePath: 'notification_queue',
	schema: schema,
	textSearchDelegate: SearchDelegate('notification_queue'),
});

export default collection;
