import { NavigationBuilder, NavigationBuilderProps } from '@camberi/firecms';

import archive_result from '../collections/archive_result';
import booking from '../collections/booking';
import certificate from '../collections/certificate';
import citizenship from '../collections/citizenship';
import contact_tracing from '../collections/contact_tracing';
import country from '../collections/country';
import cost from '../collections/cost';
import currency from '../collections/currency';
import doctor from '../collections/doctor';
import document from '../collections/document';
import employee from '../collections/employee';
import inventory from '../collections/inventory';
import location from '../collections/location';
import location_group from '../collections/location_group';
import notification_queue from '../collections/notification_queue';
import partner from '../collections/partner';
import product from '../collections/product';
import result from '../collections/result';
import service from '../collections/service';
import service_group from '../collections/service_group';
import setting from '../collections/setting';
import support from '../collections/support';
import test from '../collections/test';
import tracking from '../collections/tracking';

import batch_booking from '../views/batch_booking';
import batch_result from '../views/batch_result';
import control from '../views/control';
import new_result from '../views/new_result';
import verify from '../views/verify';

const navigation: NavigationBuilder = async ({
	authController,
	user,
}: NavigationBuilderProps) => {
	let collections = [
		booking,
		result,
		certificate,
		document,
		inventory,
		doctor,
		employee,
		location,
		location_group,
		citizenship,
		cost,
		country,
		currency,
		notification_queue,
		partner,
		product,
		service,
		service_group,
		setting,
		support,
		test,
		tracking,
		archive_result,
	];
	let extras = {
		locations: null,
		partner: null,
		roles: [] as Array<string>,
	};
	let views = [new_result, batch_booking, batch_result, verify];

	const data = await user?.getIdTokenResult();
	if (data?.claims) {
		if (data?.claims?.admin) extras.roles.push('admin');
		if (data?.claims?.superadmin) {
			extras.roles.push('superadmin');
			collections.push(contact_tracing);
			views.push(control);
		}
		extras.locations = data?.claims?.locations;
		extras.partner = data?.claims?.partner;
	}
	authController.setExtra(extras);

	return {
		collections,
		views,
	};
};

export default navigation;
