import { buildCollection, buildSchema } from '@camberi/firecms';
import 'firebase/functions';

const schema = buildSchema({
	name: 'Doctor',
	properties: {
		firstname: {
			dataType: 'string',
			title: 'Firstname',
			validation: { required: true },
		},
		lastname: {
			dataType: 'string',
			title: 'Lastname',
			validation: { required: true },
		},
		slug: {
			dataType: 'string',
			title: 'Slug',
			validation: { required: true },
		},
		title: {
			dataType: 'string',
			title: 'Title',
			validation: { required: true },
		},
		created_at: {
			dataType: 'timestamp',
			disabled: true,
			title: 'Created at',
		},
		updated_at: {
			dataType: 'timestamp',
			disabled: true,
			title: 'Updated at',
		},
	},
});

const collection = buildCollection<typeof schema>({
	description: 'Doctors',
	exportable: false,
	group: 'Employees',
	name: 'Doctors',
	pagination: true,
	permissions: ({ authController, user }) => ({
		create: authController.extra.roles.includes('superadmin'),
		delete: authController.extra.roles.includes('superadmin'),
		edit: authController.extra.roles.includes('superadmin'),
	}),
	relativePath: 'doctors',
	schema: schema,
});

export default collection;
