import { buildCollection, buildSchema } from '@camberi/firecms';
import 'firebase/functions';

const schema = buildSchema({
	name: 'Service',
	properties: {
		active: {
			dataType: 'boolean',
			title: 'Active',
		},
		name: {
			dataType: 'string',
			title: 'Name',
			validation: { required: true },
		},
		slug: {
			dataType: 'string',
			title: 'Slug',
			validation: { required: true },
		},
		partner: {
			dataType: 'reference',
			title: 'Partner',
			collectionPath: 'partners',
			previewProperties: ['name'],
		},
		image_icon: {
			config: {
				storageMeta: {
					acceptedFiles: ['image/*'],
					mediaType: 'image',
					metadata: {
						cacheControl: 'max-age=1000000',
					},
					storagePath: 'service_groups',
				},
			},
			dataType: 'string',
			description: 'Icon',
			title: 'Icon',
		},
		style: {
			dataType: 'map',
			properties: {
				background_color: {
					dataType: 'string',
					title: 'Background color',
				},
			},
			title: 'Style',
		},
		translation_key: {
			dataType: 'string',
			title: 'Translation key',
		},
		created_at: {
			disabled: true,
			title: 'Created at',
			dataType: 'timestamp',
		},
		updated_at: {
			disabled: true,
			title: 'Updated at',
			dataType: 'timestamp',
		},
	},
});

const collection = buildCollection<typeof schema>({
	description: 'Service groups',
	exportable: false,
	group: 'Content',
	initialSort: ['name', 'desc'],
	name: 'Service groups',
	pagination: true,
	permissions: ({ authController, user }) => ({
		create: authController.extra.roles.includes('superadmin'),
		delete: authController.extra.roles.includes('superadmin'),
		edit: authController.extra.roles.includes('superadmin'),
	}),
	relativePath: 'service_groups',
	schema: schema,
});

export default collection;
