import { IconButton, Tooltip } from '@material-ui/core';
import { CalendarToday } from '@material-ui/icons';

const toolbar = (
	<Tooltip title="Go to Cliento calendar">
		<IconButton
			component={'a'}
			href={'https://app.cliento.com/testmottagningen/testmottagningen/'}
			rel="noopener noreferrer"
			target="_blank"
		>
			<CalendarToday />
		</IconButton>
	</Tooltip>
);

export default toolbar;
