import { buildCollection, buildSchema } from '@camberi/firecms';
import 'firebase/functions';

const schema = buildSchema({
	customId: true,
	name: 'Setting',
	properties: {
		data: {
			config: {
				multiline: true,
			},
			dataType: 'string',
			title: 'Data',
			validation: { required: true },
		},
		created_at: {
			disabled: true,
			title: 'Created at',
			dataType: 'timestamp',
		},
		updated_at: {
			disabled: true,
			title: 'Updated at',
			dataType: 'timestamp',
		},
	},
});

const collection = buildCollection<typeof schema>({
	description: 'Settings',
	exportable: false,
	group: 'Content',
	name: 'Settings',
	pagination: true,
	permissions: ({ authController, user }) => ({
		create: authController.extra.roles.includes('superadmin'),
		delete: authController.extra.roles.includes('superadmin'),
		edit: authController.extra.roles.includes('superadmin'),
	}),
	properties: ['data', 'created_at', 'updated_at'],
	relativePath: 'settings',
	schema: schema,
});

export default collection;
