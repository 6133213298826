import { useAuthController, useSideEntityController } from '@camberi/firecms';
import firebase from 'firebase/app';
import 'firebase/functions';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { Settings } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

const BookingActions = ({ id }: { id: string }) => {
	const authController = useAuthController();
	const sideEntityController = useSideEntityController();
	const [menuToggle, setMenuToggle] = useState<any | null>(null);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const handleClick = (event: any) => {
		setMenuToggle(event.currentTarget);
		event.stopPropagation();
	};

	const handleClose = () => {
		setMenuToggle(null);
	};

	const onClickCopy = async (event: React.MouseEvent) => {
		setMenuToggle(null);

		try {
			const snackbarKey = enqueueSnackbar('Copying the result, please wait..', {
				persist: true,
				variant: 'info',
			});

			const bookingCopy = firebase
				.app()
				.functions('europe-west1')
				.httpsCallable('booking-copy');
			const result = await bookingCopy({ id: id });

			closeSnackbar(snackbarKey);

			const status = (result as any).data.status;
			if (status === 'ok') {
				enqueueSnackbar('Booking copied to results', {
					autoHideDuration: 10000,
					variant: 'success',
				});
			} else {
				const codes = (result as any).data.data.codes;
				enqueueSnackbar(`Unable to copy: ${codes.join(', ')}`, {
					autoHideDuration: 10000,
					variant: 'error',
				});
			}
		} catch (error) {
			console.log(error);
			enqueueSnackbar('Unable to copy booking.', {
				autoHideDuration: 10000,
				variant: 'error',
			});
		}
	};

	const onClickBookingPDF = async (event: React.MouseEvent) => {
		setMenuToggle(null);
		authController?.loggedUser?.getIdToken().then(function (idToken: string) {
			window.open(
				`https://europe-west1-testmottagningen.cloudfunctions.net/booking-pdf?id=${id}&token=${idToken}`,
				'_blank'
			);
		});
	};

	const onClickBookingLabelPDF = async (event: React.MouseEvent) => {
		setMenuToggle(null);
		authController?.loggedUser?.getIdToken().then(function (idToken: string) {
			window.open(
				`https://europe-west1-testmottagningen.cloudfunctions.net/booking-pdf?id=${id}&token=${idToken}&type=label`,
				'_blank'
			);
		});
	};

	const onClickCheck = async (event: React.MouseEvent) => {
		setMenuToggle(null);
		sideEntityController.open({
			collectionPath: '/bookings',
			entityId: id,
			selectedSubpath: 'check',
		});
	};

	return (
		<>
			<Button
				aria-controls="simple-menu"
				aria-haspopup="true"
				onClick={handleClick}
			>
				<Settings />
			</Button>
			<Menu
				anchorEl={menuToggle}
				elevation={2}
				id="simple-menu"
				keepMounted
				open={Boolean(menuToggle)}
				onClose={handleClose}
			>
				<MenuItem onClick={onClickBookingPDF}>Open booking PDF</MenuItem>
				<MenuItem onClick={onClickBookingLabelPDF}>
					Open booking label PDF
				</MenuItem>
				<MenuItem onClick={onClickCopy}>Copy to result</MenuItem>
				<MenuItem>-------------------------------</MenuItem>
				<MenuItem onClick={onClickCheck}>Check</MenuItem>
			</Menu>
		</>
	);
};

export default BookingActions;
