import { useSideEntityController } from '@camberi/firecms';
import { FormikHelpers, useFormik } from 'formik';
import 'firebase/functions';
import { green, red } from '@material-ui/core/colors';
import {
	Box,
	Button,
	Container,
	FormControl,
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
} from '@material-ui/core';
import { Cancel, CheckBox, Settings } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { SnackbarKey, useSnackbar } from 'notistack';
import Papa from 'papaparse';
import { useEffect, useRef, useState } from 'react';

const useStyles = makeStyles((theme) => ({
	root: {
		marginBottom: '10px',
		marginTop: '10px',
		'& .MuiTextField-root': {
			margin: theme.spacing(1, 0),
		},
	},
}));

function ComponentView() {
	let fileReference = useRef();
	const sideEntityController = useSideEntityController();
	const classes = useStyles();
	const [disable, setDisable] = useState(false);
	const [fileData, setFileData] = useState('');
	const [parsedData, setParsedData] = useState<any[]>([]);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	useEffect(() => {
		parseData(fileData);
	}, [fileData]);

	const handleSubmit = async (
		values: any,
		{ resetForm, setSubmitting }: FormikHelpers<any>
	) => {
		setFileData('');
		setParsedData([]);
		readFile(values.file);
		setSubmitting(false);
	};

	const handleBatchAdd = async (event: any) => {
		const test = false;

		setDisable(true);
		const snackbarKey = enqueueSnackbar('Adding booking(s), please wait..', {
			persist: true,
			variant: 'info',
		});

		for (let key in parsedData) {
			const data = {
				agreement_accepted: parsedData[key].agreement_accepted,
				citizenship: parsedData[key].citizenship,
				date_of_birth: parsedData[key].date_of_birth,
				email: parsedData[key].email,
				firstname: parsedData[key].firstname,
				gender: parsedData[key].gender,
				lastname: parsedData[key].lastname,
				location: parsedData[key].location,
				passport_number: parsedData[key].passport_number,
				phone_number: parsedData[key].phone_number,
				service: parsedData[key].service,
			};

			if (test) {
				console.log(data);
				await new Promise((r) => setTimeout(r, 250));
				parsedData[key].booking_status = (key as any) % 2 ? 'error' : 'add';
				parsedData[key].booking_status_message = `Id: ${
					(key as any) % 2 ? 'demo-2' : 'demo-1'
				}`;
				parsedData[key].booking_status_id =
					(key as any) % 2 ? 'demo-2' : 'demo-1';
			} else {
				const url =
					'https://europe-west1-testmottagningen.cloudfunctions.net/booking-register';

				const options = {
					body: JSON.stringify(data),
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
					},
					method: 'POST',
				};

				const response = await fetch(url, options);
				const result = await response.json();

				if (!result || (result && result.status === 'error')) {
					parsedData[key].booking_status = 'error';
					parsedData[key].booking_status_message = 'Unable to add booking';
				} else if (result && result.data && result.data.id) {
					parsedData[key].booking_status = 'add';
					parsedData[key].booking_status_id = result.data.id;
					parsedData[key].booking_status_message = `Id: ${result.data.id}`;
				}
			}

			const tmp = [...parsedData];
			setParsedData(tmp);
		}

		closeSnackbar(snackbarKey);

		let snackbarCloseKey: SnackbarKey = '';
		const snackbarActions = () => (
			<Button onClick={() => closeSnackbar(snackbarCloseKey)}>Close</Button>
		);

		snackbarCloseKey = enqueueSnackbar('New booking(s) added.', {
			action: snackbarActions,
			persist: true,
			variant: 'success',
		});
		setDisable(false);
	};

	const onSettingsClick = async (id: any) => {
		sideEntityController.open({
			collectionPath: '/bookings',
			entityId: id,
		});
	};

	const parseData = (data: any) => {
		if (!data) return;

		let map: { [key: string]: string } = {
			Agreement: 'agreement_accepted',
			Citizenship: 'citizenship',
			'Date of birth': 'date_of_birth',
			'E-Mail': 'email',
			'First Name': 'firstname',
			Gender: 'gender',
			'Last Name': 'lastname',
			Location: 'location',
			Nationality: 'citizenship',
			'Passport Number': 'passport_number',
			'Phone Number': 'phone_number',
			Service: 'service',
			Surname: 'lastname',
		};

		const result: any = Papa.parse(data, {
			header: true,
			transformHeader: function (header: any) {
				header = header.trim();
				header = map[header];
				return header;
			},
		});
		if (result && result.data) {
			setParsedData(result.data);
		}
	};

	const readFile = (file: any) => {
		const reader = new FileReader();
		reader.addEventListener('load', (event: any) => {
			setFileData(event.target.result);
		});
		reader.readAsText(file);
	};

	const formik = useFormik({
		initialValues: {
			file: '',
		},
		onSubmit: handleSubmit,
	});

	return (
		<Container>
			<Box
				alignItems={'center'}
				display="flex"
				flexDirection={'column'}
				justifyItems={'center'}
				m="auto"
			>
				<h3>Batch add bookings</h3>

				<form className={classes.root} onSubmit={formik.handleSubmit}>
					<FormControl>
						<TextField
							autoComplete="off"
							error={formik.touched.file && Boolean(formik.errors.file)}
							helperText={formik.touched.file && formik.errors.file}
							id="file"
							inputRef={fileReference}
							onChange={(event) => {
								const file = (event.currentTarget as any).files[0];
								formik.setFieldValue('file', file);
							}}
							label="File"
							type="file"
							value={undefined}
							variant="outlined"
						/>

						<Button
							color="primary"
							disabled={formik.isSubmitting}
							variant="contained"
							type="submit"
						>
							Check
						</Button>
					</FormControl>
				</form>

				{parsedData.length > 0 && (
					<>
						<Box m={2}>
							<h3>Bookings found in CSV file</h3>
							<TableContainer component={Paper}>
								<Table aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell>First name</TableCell>
											<TableCell>Last name</TableCell>
											<TableCell>E-mail</TableCell>
											<TableCell>Phone number</TableCell>
											<TableCell>Gender</TableCell>
											<TableCell>Citizenship</TableCell>
											<TableCell>Date of birth</TableCell>
											<TableCell>Passport number</TableCell>
											<TableCell>Location</TableCell>
											<TableCell>Service</TableCell>
											<TableCell>Agreement</TableCell>
											<TableCell>Status</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{parsedData.map((row: any, i: any) => (
											<TableRow key={i}>
												<TableCell>
													{'firstname' in row
														? row['firstname']
														: `Missing "firstname" column in CSV`}
												</TableCell>
												<TableCell>
													{'lastname' in row
														? row['lastname']
														: `Missing "lastname" column in CSV`}
												</TableCell>
												<TableCell>
													{'email' in row
														? row['email']
														: `Missing "email" column in CSV`}
												</TableCell>
												<TableCell>
													{'phone_number' in row
														? row['phone_number']
														: `Missing "phone_number" column in CSV`}
												</TableCell>
												<TableCell>
													{'gender' in row
														? row['gender']
														: `Missing "gender" column in CSV`}
												</TableCell>
												<TableCell>
													{'citizenship' in row
														? row['citizenship']
														: `Missing "citizenship" column in CSV`}
												</TableCell>
												<TableCell>
													{'date_of_birth' in row
														? row['date_of_birth']
														: `Missing "date_of_birth" column in CSV`}
												</TableCell>
												<TableCell>
													{'passport_number' in row
														? row['passport_number']
														: `Missing "passport_number" column in CSV`}
												</TableCell>
												<TableCell>
													{'location' in row
														? row['location']
														: `Missing "location" column in CSV`}
												</TableCell>
												<TableCell>
													{'service' in row
														? row['service']
														: `Missing "service" column in CSV`}
												</TableCell>
												<TableCell>
													{'agreement_accepted' in row
														? row['agreement_accepted']
														: `Missing "agreement_accepted" column in CSV`}
												</TableCell>
												<TableCell>
													<Grid container direction="row" alignItems="center">
														<Grid item>
															{row.booking_status === 'add' && (
																<span>
																	<CheckBox style={{ color: green[500] }} />
																	&#160;
																</span>
															)}
															{row.booking_status === 'error' && (
																<span>
																	<Cancel style={{ color: red[500] }} />
																	&#160;
																</span>
															)}
														</Grid>
														<Grid item>
															{row.booking_status_message && (
																<span>{row.booking_status_message}</span>
															)}
														</Grid>
														<Grid item>
															{row.booking_status === 'add' && (
																<span>
																	<Button
																		aria-controls="simple-menu"
																		aria-haspopup="true"
																		onClick={() =>
																			onSettingsClick(row.booking_status_id)
																		}
																	>
																		<Settings />
																	</Button>
																</span>
															)}
														</Grid>
													</Grid>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Box>

						<Box m={3}>
							<Button
								color="primary"
								disabled={disable}
								onClick={handleBatchAdd}
								variant="contained"
							>
								Batch add
							</Button>
						</Box>
					</>
				)}
			</Box>
		</Container>
	);
}

const view = {
	description: 'Batch add bookings',
	group: 'Admin',
	name: 'Batch add bookings',
	path: ['batch_booking'],
	view: <ComponentView />,
};

export default view;
