import { buildCollection, buildSchema } from '@camberi/firecms';
import 'firebase/functions';

const schema = buildSchema({
	customId: true,
	name: 'Partner',
	properties: {
		booking_footer: {
			columnWidth: 500,
			config: {
				multiline: true,
			},
			dataType: 'string',
			title: 'Booking footer',
		},
		booking_form_version: {
			dataType: 'number',
			title: 'Booking form version',
		},
		certificate_covidbevis: {
			dataType: 'boolean',
			title: 'Certificate covidbevis',
		},
		certificate_footer: {
			columnWidth: 500,
			config: {
				multiline: true,
			},
			dataType: 'string',
			title: 'Certificate footer',
		},
		certificate_protected: {
			dataType: 'boolean',
			title: 'Certificate is protected?',
		},
		certificate_url: {
			dataType: 'string',
			title: 'Certificate url',
		},
		company_address_1: {
			dataType: 'string',
			title: 'Company - Address',
		},
		company_city: {
			dataType: 'string',
			title: 'Company - City',
		},
		company_name: {
			dataType: 'string',
			title: 'Company - Name',
		},
		company_registration_number: {
			dataType: 'string',
			title: 'Company - Registration number',
		},
		company_zip_code: {
			dataType: 'string',
			title: 'Company - Zip code',
		},
		contact_email: {
			dataType: 'string',
			title: 'Contact - Email',
		},
		contact_phone_number: {
			dataType: 'string',
			title: 'Contact - Phone number',
		},
		email_from: {
			dataType: 'string',
			title: 'Email from',
		},
		name: {
			dataType: 'string',
			title: 'Name',
			validation: { required: true },
		},
		image_logo: {
			config: {
				storageMeta: {
					acceptedFiles: ['image/*'],
					mediaType: 'image',
					metadata: {
						cacheControl: 'max-age=1000000',
					},
					storagePath: 'partners',
				},
			},
			dataType: 'string',
			description: 'Upload logo image',
			title: 'Logo image',
		},
		notify_booking: {
			dataType: 'boolean',
			title: 'Notify bookings',
		},
		sms_from: {
			dataType: 'string',
			title: 'SMS from',
			validation: { max: 8, required: true },
		},
		report_email: {
			dataType: 'string',
			title: 'Report email',
		},
		support_email: {
			dataType: 'string',
			title: 'Support email',
		},
		verify_url: {
			dataType: 'string',
			title: 'Verify url',
		},
		unilabs_customer_code: {
			dataType: 'string',
			title: 'Unilabs customer code',
		},
		trustpilot_email: {
			dataType: 'string',
			title: 'Trustpilot email',
		},
		created_at: {
			dataType: 'timestamp',
			disabled: true,
			title: 'Created at',
		},
		updated_at: {
			dataType: 'timestamp',
			disabled: true,
			title: 'Updated at',
		},
	},
});

const collection = buildCollection<typeof schema>({
	description: 'Partners',
	exportable: false,
	group: 'Content',
	name: 'Partners',
	pagination: true,
	permissions: ({ authController, user }) => ({
		create: authController.extra.roles.includes('superadmin'),
		delete: authController.extra.roles.includes('superadmin'),
		edit: authController.extra.roles.includes('superadmin'),
	}),
	relativePath: 'partners',
	schema: schema,
});

export default collection;
