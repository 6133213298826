import { useAuthController } from '@camberi/firecms';
import firebase from 'firebase/app';
import { FormikHelpers, useFormik } from 'formik';
import 'firebase/functions';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	FormControlLabel,
	IconButton,
	InputLabel,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Select,
	TextField,
	Typography,
} from '@material-ui/core';

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CloseIcon from '@material-ui/icons/Close';
import ColorizeIcon from '@material-ui/icons/Colorize';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import Person from '@material-ui/icons/Person';

import { makeStyles } from '@material-ui/core/styles';
import { SnackbarKey, useSnackbar } from 'notistack';
import { useRef, useState } from 'react';

const useStyles = makeStyles((theme) => ({
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
	},
	root: {
		marginBottom: '10px',
		marginTop: '10px',
		'& .MuiTextField-root': {
			margin: theme.spacing(1, 0),
		},
		'& .MuiAccordionDetails-root': {
			display: 'block',
		},
	},
}));

function ComponentView() {
	const authController = useAuthController();
	let fileReference = useRef();
	const classes = useStyles();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [showConfirmation, setShowConfirmation] = useState(false);

	const handleConfirmationDialog = (show: boolean) => {
		setShowConfirmation(show);
	};

	const handleSubmit = async (
		values: any,
		{ resetForm, setSubmitting }: FormikHelpers<any>
	) => {
		const idToken = await authController?.loggedUser?.getIdToken();

		const data = {
			analyze_information: values.analyze_information,
			analyze_method: values.analyze_method,
			analyze_result: values.analyze_result,
			analyze_value: values.analyze_value,
			count_test: values.count_test,
			covidbevis: values.covidbevis,
			file: values.file ? await toBase64(values.file) : null,
			notify: values.notify,
			patient_id: values.patient_id,
			tested_at: values.tested_at,
			token: idToken,
			verified: values.verified,
		};

		handleConfirmationDialog(false);

		const snackbarKey = enqueueSnackbar('Adding new result, please wait..', {
			persist: true,
			variant: 'info',
		});

		const resultsUpload = firebase
			.app()
			.functions('europe-west1')
			.httpsCallable('result-upload');
		const result = await resultsUpload(data);

		closeSnackbar(snackbarKey);

		const status = (result as any).data.status;
		if (status === 'ok') {
			enqueueSnackbar('New result added.', {
				autoHideDuration: 10000,
				variant: 'success',
			});

			(fileReference as any).current.value = null;
			resetForm();
		} else {
			const codes = (result as any).data.data.codes;
			let message = (result as any).data.data.message;
			if (!message) {
				message = `Unable to add new result: ${
					codes ? codes.join(', ') : 'unknown error'
				}`;
			}

			let snackbarCloseKey: SnackbarKey = '';
			const snackbarActions = () => (
				<Button onClick={() => closeSnackbar(snackbarCloseKey)}>Close</Button>
			);

			snackbarCloseKey = enqueueSnackbar(message, {
				action: snackbarActions,
				persist: true,
				variant: 'error',
			});
		}
		setSubmitting(false);
	};

	const toBase64 = (file: any) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	const formik = useFormik({
		initialValues: {
			analyze_information: '',
			analyze_method: '',
			analyze_result: 'negative',
			analyze_value: '',
			count_test: 1,
			covidbevis: true,
			file: '',
			notify: true,
			patient_id: '',
			tested_at: '',
			verified: true,
		},
		onSubmit: handleSubmit,
	});

	return (
		<>
			<Box
				alignItems={'center'}
				display="flex"
				flexDirection={'column'}
				justifyItems={'center'}
				m="auto"
			>
				<h3>Add new result</h3>

				<form className={classes.root} onSubmit={formik.handleSubmit}>
					<FormControl>
						<TextField
							autoComplete="off"
							error={formik.touched.file && Boolean(formik.errors.file)}
							helperText={formik.touched.file && formik.errors.file}
							id="file"
							inputRef={fileReference}
							onChange={(event) => {
								const file = (event.currentTarget as any).files[0];
								formik.setFieldValue('file', file);
							}}
							label="File"
							type="file"
							value={undefined}
							variant="outlined"
						/>
						<TextField
							autoComplete="off"
							error={
								formik.touched.patient_id && Boolean(formik.errors.patient_id)
							}
							helperText={formik.touched.patient_id && formik.errors.patient_id}
							id="patient_id"
							onChange={formik.handleChange}
							label="Patient id"
							required
							value={formik.values.patient_id}
							variant="outlined"
						/>
						<TextField
							autoComplete="off"
							error={
								formik.touched.tested_at && Boolean(formik.errors.tested_at)
							}
							helperText={formik.touched.tested_at && formik.errors.tested_at}
							id="tested_at"
							onChange={formik.handleChange}
							label="Tested at"
							type="datetime-local"
							value={formik.values.tested_at}
							variant="outlined"
						/>
						<FormControl>
							<InputLabel id="analyze_method">Analyze method</InputLabel>
							<Select
								error={
									formik.touched.analyze_method &&
									Boolean(formik.errors.analyze_method)
								}
								id="analyze_method"
								label="Analyze method"
								name="analyze_method"
								onChange={formik.handleChange}
								value={formik.values.analyze_method}
								variant="outlined"
							>
								<MenuItem value={'capillary_blood'}>Capillary blood</MenuItem>
								<MenuItem value={'nasal'}>Nasal</MenuItem>
								<MenuItem value={'nasopharyngeal'}>Nasopharyngeal</MenuItem>
								<MenuItem value={'oropharyngeal'}>Oropharyngeal</MenuItem>
								<MenuItem value={'saliva'}>Saliva</MenuItem>
							</Select>
						</FormControl>
						<TextField
							autoComplete="off"
							error={
								formik.touched.count_test && Boolean(formik.errors.count_test)
							}
							helperText={formik.touched.count_test && formik.errors.count_test}
							id="count_test"
							onChange={formik.handleChange}
							label="Tests"
							type="number"
							value={formik.values.count_test}
							variant="outlined"
						/>
						<TextField
							autoComplete="off"
							error={
								formik.touched.analyze_value &&
								Boolean(formik.errors.analyze_value)
							}
							helperText={
								formik.touched.analyze_value && formik.errors.analyze_value
							}
							id="analyze_value"
							onChange={formik.handleChange}
							label="Analyze value"
							value={formik.values.analyze_value}
							variant="outlined"
						/>

						<Accordion className={classes.root}>
							<AccordionSummary
								aria-controls="panel1a-content"
								expandIcon={<ExpandMoreIcon />}
								id="panel1a-header"
							>
								<Typography>More settings</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Box
									alignItems={'left'}
									display="flex"
									flexDirection={'column'}
									justifyItems={'left'}
								>
									<TextField
										autoComplete="off"
										error={
											formik.touched.analyze_information &&
											Boolean(formik.errors.analyze_information)
										}
										helperText={
											formik.touched.analyze_information &&
											formik.errors.analyze_information
										}
										id="analyze_information"
										onChange={formik.handleChange}
										label="Analyze information"
										multiline
										value={formik.values.analyze_information}
										variant="outlined"
									/>
									<FormControl>
										<InputLabel id="analyze_result">Analyze result</InputLabel>
										<Select
											error={
												formik.touched.analyze_result &&
												Boolean(formik.errors.analyze_result)
											}
											id="analyze_result"
											labelId="analyze_result"
											name="analyze_result"
											onChange={formik.handleChange}
											value={formik.values.analyze_result}
											variant="outlined"
										>
											<MenuItem value={'inconclusive'}>Inconclusive</MenuItem>
											<MenuItem value={'negative'}>Negative</MenuItem>
											<MenuItem value={'positive'}>Positive</MenuItem>
											<MenuItem value={'value'}>Value</MenuItem>
										</Select>
									</FormControl>
									<FormControlLabel
										control={
											<Checkbox
												checked={formik.values.notify}
												color="primary"
												onChange={formik.handleChange}
												name="notify"
											/>
										}
										label="Send notification"
									/>
									<FormControlLabel
										control={
											<Checkbox
												checked={formik.values.verified}
												color="primary"
												onChange={formik.handleChange}
												name="verified"
											/>
										}
										label='Set status to "verified"'
									/>
									<FormControlLabel
										control={
											<Checkbox
												checked={formik.values.covidbevis}
												color="primary"
												onChange={formik.handleChange}
												name="covidbevis"
											/>
										}
										label="Create covidbevis?"
									/>
								</Box>
							</AccordionDetails>
						</Accordion>

						<Button
							color="primary"
							disabled={formik.isSubmitting}
							onClick={() => handleConfirmationDialog(true)}
							variant="contained"
						>
							Check
						</Button>

						<Dialog
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description"
							keepMounted
							onClose={() => handleConfirmationDialog(false)}
							open={showConfirmation}
						>
							<DialogTitle disableTypography id="alert-dialog-title">
								<Typography>Please verify</Typography>
								<IconButton
									aria-label="close"
									className={classes.closeButton}
									onClick={() => handleConfirmationDialog(false)}
								>
									<CloseIcon />
								</IconButton>
							</DialogTitle>
							<DialogContent dividers>
								<DialogContentText id="alert-dialog-description">
									<List>
										<ListItem>
											<ListItemIcon>
												<Person />
											</ListItemIcon>
											<ListItemText
												primary={formik.values.patient_id}
												secondary="Patient id"
											/>
										</ListItem>
										<ListItem>
											<ListItemIcon>
												<InsertDriveFileIcon />
											</ListItemIcon>
											<ListItemText
												primary={formik.values.analyze_result}
												secondary="Analyze result"
											/>
										</ListItem>
										<ListItem>
											<ListItemIcon>
												<ColorizeIcon />
											</ListItemIcon>
											<ListItemText
												primary={formik.values.analyze_method}
												secondary="Analyze method"
											/>
										</ListItem>
										<ListItem>
											<ListItemIcon>
												<AccessTimeIcon />
											</ListItemIcon>
											<ListItemText
												primary={formik.values.tested_at}
												secondary="Tested at"
											/>
										</ListItem>
									</List>
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button
									color="primary"
									disabled={formik.isSubmitting}
									onClick={formik.submitForm}
									variant="contained"
								>
									Add
								</Button>
							</DialogActions>
						</Dialog>
					</FormControl>
				</form>
			</Box>
		</>
	);
}

const view = {
	description: 'Add a new result with image',
	group: 'Admin',
	name: 'New result',
	path: ['new_result'],
	view: <ComponentView />,
};

export default view;
