import { buildCollection, buildSchema } from '@camberi/firecms';
import 'firebase/functions';

const schema = buildSchema({
	customId: true,
	name: 'Currencies',
	properties: {
		code: {
			dataType: 'string',
			title: 'Code',
			validation: { required: true },
		},
		name: {
			dataType: 'string',
			title: 'Name',
			validation: { required: true },
		},
		created_at: {
			dataType: 'timestamp',
			disabled: true,
			title: 'Created at',
		},
		updated_at: {
			dataType: 'timestamp',
			disabled: true,
			title: 'Updated at',
		},
	},
});

const collection = buildCollection<typeof schema>({
	description: 'Currencies',
	exportable: true,
	group: 'Content',
	initialSort: ['name', 'asc'],
	name: 'Currencies',
	pagination: true,
	permissions: ({ authController, user }) => ({
		create: authController.extra.roles.includes('superadmin'),
		delete: authController.extra.roles.includes('superadmin'),
		edit: authController.extra.roles.includes('superadmin'),
	}),
	relativePath: 'currencies',
	schema: schema,
});

export default collection;
