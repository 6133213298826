import { Locale } from '@camberi/firecms';
import firebase from 'firebase/app';

const is_sandbox = process.env.REACT_APP_ENVIRONMENT === 'sandbox';

let firebase_configuration = {
	apiKey: 'AIzaSyCOiovEY_vKiuTdpPS-SYfnUThUS9jg1mc',
	authDomain: 'testmottagningen.firebaseapp.com',
	projectId: 'testmottagningen',
	storageBucket: 'testmottagningen.appspot.com',
	messagingSenderId: '550453163642',
	appId: '1:550453163642:web:4ab660ec93257430482dc3',
};

if(is_sandbox) {
	firebase_configuration = {
		apiKey: "AIzaSyAnvjoRAXCGsKLTNzI6wLZ6xH2yQidgS_I",
		authDomain: "testmottagningen-sandbox.firebaseapp.com",
		projectId: "testmottagningen-sandbox",
		storageBucket: "testmottagningen-sandbox.appspot.com",
		messagingSenderId: "47628886207",
		appId: "1:47628886207:web:3912d6faaa1256df84660a"
	};
}

const data = {
	authentication: {
		disableSignUp: {
			status: true,
		},
		provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
	},
	firebase: firebase_configuration,
	site: {
		date_time_format: 'yyyy-MM-dd HH:mm:ss',
		font_family: "-apple-system, BlinkMacSystemFont, 'Helvetica Neue', Arial",
		locale: 'sv' as Locale,
		logo: '/logo.svg',
		name: 'Testmottagningen',
		primary_color: '#30887a',
		secondary_color: '#3e3f54',
	}
};

export default data;