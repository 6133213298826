import { buildCollection, buildSchema } from '@camberi/firecms';
import 'firebase/functions';

const schema = buildSchema({
	name: 'Location',
	properties: {
		active: {
			dataType: 'boolean',
			title: 'Active',
		},
		name: {
			dataType: 'string',
			title: 'Name',
			validation: { required: true },
		},
		slug: {
			dataType: 'string',
			title: 'Slug',
			validation: { required: true },
		},
		partner: {
			dataType: 'reference',
			title: 'Partner',
			collectionPath: 'partners',
			previewProperties: ['name'],
		},
		translation_key: {
			dataType: 'string',
			title: 'Translation key',
		},
		created_at: {
			disabled: true,
			title: 'Created at',
			dataType: 'timestamp',
		},
		updated_at: {
			disabled: true,
			title: 'Updated at',
			dataType: 'timestamp',
		},
	},
});

const collection = buildCollection<typeof schema>({
	description: 'Location groups',
	exportable: false,
	group: 'Content',
	initialSort: ['name', 'desc'],
	name: 'Location groups',
	pagination: true,
	permissions: ({ authController, user }) => ({
		create: authController.extra.roles.includes('superadmin'),
		delete: authController.extra.roles.includes('superadmin'),
		edit: authController.extra.roles.includes('superadmin'),
	}),
	relativePath: 'location_groups',
	schema: schema,
});

export default collection;
