import { useAuthController } from '@camberi/firecms';
import firebase from 'firebase/app';
import { FormikHelpers, useFormik } from 'formik';
import 'firebase/functions';
import { grey, green, red, yellow } from '@material-ui/core/colors';
import {
	Box,
	Button,
	Container,
	FormControl,
	InputLabel,
	Grid,
	Paper,
	MenuItem,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
} from '@material-ui/core';
import {
	AccessTime,
	Cancel,
	CheckBox,
	Help,
	Warning,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { SnackbarKey, useSnackbar } from 'notistack';
import Papa from 'papaparse';
import { useEffect, useRef, useState } from 'react';

interface Partner {
	id: string;
	name: string;
	tested_at: boolean;
}

const partners: { [key: string]: Partner } = {
	amplexa_genetics: {
		id: 'patient_id',
		name: 'Amplexa Genetics',
		tested_at: true,
	},
	express_care: {
		id: 'external_id',
		name: 'Express care',
		tested_at: false,
	},
};

const useStyles = makeStyles((theme) => ({
	root: {
		marginBottom: '10px',
		marginTop: '10px',
		'& .MuiTextField-root': {
			margin: theme.spacing(1, 0),
		},
	},
}));

function ComponentView() {
	const authController = useAuthController();
	let fileReference = useRef();
	const classes = useStyles();
	const [disable, setDisable] = useState(false);
	const [fileData, setFileData] = useState('');
	const [format, setFormat] = useState('');
	const [parsedData, setParsedData] = useState<any[]>([]);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	useEffect(() => {
		parseData(fileData);
	}, [fileData]);

	const getResult = (data: any) => {
		let out = null;
		if (!data) return null;

		if (data.analyze_result) {
			data.analyze_result = data.analyze_result.toLowerCase();

			if (data.analyze_result.indexOf('inconclusive') !== -1) {
				out = 'inconclusive';
			} else if (
				data.analyze_result.indexOf('negative') !== -1 ||
				data.analyze_result.indexOf('negativ') !== -1 ||
				data.analyze_result.indexOf('negativt') !== -1
			) {
				out = 'negative';
			} else if (
				data.analyze_result.indexOf('positive') !== -1 ||
				data.analyze_result.indexOf('positivt') !== -1
			) {
				out = 'positive';
			}
		}

		return out;
	};

	const handleSubmit = async (
		values: any,
		{ resetForm, setSubmitting }: FormikHelpers<any>
	) => {
		setFileData('');
		setParsedData([]);
		setFormat(values.format);
		readFile(values.file);
		setSubmitting(false);
	};

	const handleBatchAdd = async (event: any) => {
		const idToken = await authController?.loggedUser?.getIdToken();
		const test = false;

		setDisable(true);
		const snackbarKey = enqueueSnackbar('Adding result(s), please wait..', {
			persist: true,
			variant: 'info',
		});

		for (let key in parsedData) {
			const analyze_result = getResult(parsedData[key]);
			if (!analyze_result) {
				parsedData[key].upload_status = 'ignore';
				parsedData[key].upload_status_message =
					'Ignored, not negative or positive result';
				const tmp = [...parsedData];
				setParsedData(tmp);
				continue;
			}

			const data = {
				analyze_result: analyze_result,
				batch: true,
				count_test: 1,
				covidbevis: test ? false : true,
				external_id: parsedData[key].external_id,
				notify: test ? false : true,
				patient_id: parsedData[key].patient_id,
				tested_at: parsedData[key].tested_at,
				token: idToken,
				verified: test ? false : true,
			};

			const resultsUpload = firebase
				.app()
				.functions('europe-west1')
				.httpsCallable('result-upload');
			const result = await resultsUpload(data);

			const status = (result as any).data.status;
			if (status === 'ok') {
				parsedData[key].upload_status = 'add';
			} else {
				parsedData[key].upload_status = 'error';

				const codes = (result as any).data.data.codes;
				parsedData[key].upload_status_message = (
					result as any
				).data.data.message;
				if (!parsedData[key].upload_status_message) {
					parsedData[key].upload_status_message = `Unable to add new result: ${
						codes ? codes.join(', ') : 'unknown error'
					}`;
				}
			}

			const tmp = [...parsedData];
			setParsedData(tmp);
		}

		closeSnackbar(snackbarKey);

		let snackbarCloseKey: SnackbarKey = '';
		const snackbarActions = () => (
			<Button onClick={() => closeSnackbar(snackbarCloseKey)}>Close</Button>
		);

		snackbarCloseKey = enqueueSnackbar('New result(s) added.', {
			action: snackbarActions,
			persist: true,
			variant: 'success',
		});
		setDisable(false);
	};

	const parseData = (data: any) => {
		if (!data) return;

		let map: { [key: string]: string } = {
			'Prov ID': 'external_id',
			Result: 'analyze_result',
			Resultat: 'analyze_result',
			'Sample ID': 'external_id',
		};

		const result: any = Papa.parse(data, {
			header: true,
			transformHeader: function (header: any) {
				header = header.trim();
				if (format === 'express_care' && map[header]) {
					header = map[header];
				}

				return header;
			},
		});
		if (result && result.data) {
			setParsedData(result.data);
		}
	};

	const readFile = (file: any) => {
		const reader = new FileReader();
		reader.addEventListener('load', (event: any) => {
			setFileData(event.target.result);
		});
		reader.readAsText(file);
	};

	const formik = useFormik({
		initialValues: {
			file: '',
			format: '',
		},
		onSubmit: handleSubmit,
	});

	return (
		<Container>
			<Box
				alignItems={'center'}
				display="flex"
				flexDirection={'column'}
				justifyItems={'center'}
				m="auto"
			>
				<h3>Batch add results</h3>

				<form className={classes.root} onSubmit={formik.handleSubmit}>
					<FormControl>
						<InputLabel id="format">Format</InputLabel>
						<Select
							error={formik.touched.format && Boolean(formik.errors.format)}
							id="format"
							labelId="format"
							name="format"
							onChange={formik.handleChange}
							value={formik.values.format}
							variant="outlined"
						>
							{Object.keys(partners).map((key: any) => (
								<MenuItem value={key}>{partners[key].name}</MenuItem>
							))}
						</Select>
						<TextField
							autoComplete="off"
							error={formik.touched.file && Boolean(formik.errors.file)}
							helperText={formik.touched.file && formik.errors.file}
							id="file"
							inputRef={fileReference}
							onChange={(event) => {
								const file = (event.currentTarget as any).files[0];
								formik.setFieldValue('file', file);
							}}
							label="File"
							type="file"
							value={undefined}
							variant="outlined"
						/>

						<Button
							color="primary"
							disabled={formik.isSubmitting}
							variant="contained"
							type="submit"
						>
							Check
						</Button>
					</FormControl>
				</form>

				{parsedData.length > 0 && (
					<>
						<Box m={2}>
							<h3>Results found in CSV file</h3>
							<TableContainer component={Paper}>
								<Table aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell>Id ({partners[format].id})</TableCell>
											<TableCell>Result</TableCell>
											{partners[format].tested_at && (
												<TableCell>Tested at</TableCell>
											)}
											<TableCell>Status</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{parsedData.map((row: any, i: any) => (
											<TableRow key={i}>
												<TableCell component="th" scope="row">
													{row[partners[format].id]
														? row[partners[format].id]
														: `Missing "${partners[format].id}" column in CSV`}
												</TableCell>
												<TableCell>
													<Grid container direction="row" alignItems="center">
														<Grid item>
															{getResult(row) === 'inconclusive' && (
																<span>
																	<Help style={{ color: grey[500] }} />
																	&#160;
																</span>
															)}
															{getResult(row) === 'negative' && (
																<span>
																	<CheckBox style={{ color: green[500] }} />
																	&#160;
																</span>
															)}
															{getResult(row) === 'positive' && (
																<span>
																	<Cancel style={{ color: red[500] }} />
																	&#160;
																</span>
															)}
															{!getResult(row) && (
																<span>
																	<Warning style={{ color: yellow[500] }} />
																	&#160;
																</span>
															)}
														</Grid>
														<Grid item>
															{row.analyze_result
																? row.analyze_result
																: 'Missing "analyze_result" column in CSV'}
														</Grid>
													</Grid>
												</TableCell>
												{partners[format].tested_at && (
													<TableCell>
														<Grid container direction="row" alignItems="center">
															<Grid>
																<span>
																	<AccessTime />
																	&#160;
																</span>
															</Grid>
															<Grid item>
																{row.tested_at
																	? row.tested_at
																	: 'Missing "tested_at" column in CSV'}
															</Grid>
														</Grid>
													</TableCell>
												)}
												<TableCell>
													<Grid container direction="row" alignItems="center">
														<Grid item>
															{row.upload_status === 'add' && (
																<span>
																	<CheckBox style={{ color: green[500] }} />
																	&#160;
																</span>
															)}
															{row.upload_status === 'error' && (
																<span>
																	<Cancel style={{ color: red[500] }} />
																	&#160;
																</span>
															)}
															{row.upload_status === 'ignore' && (
																<span>
																	<Warning style={{ color: yellow[500] }} />
																	&#160;
																</span>
															)}
														</Grid>
														<Grid item>
															{row.upload_status_message && (
																<span>{row.upload_status_message}</span>
															)}
														</Grid>
													</Grid>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Box>

						<Box m={3}>
							<Button
								color="primary"
								disabled={disable}
								onClick={handleBatchAdd}
								variant="contained"
							>
								Batch add
							</Button>
						</Box>
					</>
				)}
			</Box>
		</Container>
	);
}

const view = {
	description: 'Batch add results',
	group: 'Admin',
	name: 'Batch add results',
	path: ['batch_result'],
	view: <ComponentView />,
};

export default view;
