import { buildCollection, buildSchema } from '@camberi/firecms';
import 'firebase/functions';

const schema = buildSchema({
	customId: true,
	name: 'Location',
	properties: {
		active: {
			dataType: 'boolean',
			title: 'Active',
		},
		address: {
			dataType: 'string',
			title: 'Address',
			validation: { required: true },
		},
		address_url: {
			dataType: 'string',
			title: 'Address url',
			validation: { required: true },
		},
		country: {
			collectionPath: 'countries',
			dataType: 'reference',
			previewProperties: ['code', 'name'],
			title: 'Country',
		},
		city: {
			dataType: 'string',
			title: 'City',
			validation: { required: true },
		},
		doctor: {
			dataType: 'reference',
			title: 'Doctor',
			collectionPath: 'doctors',
			previewProperties: ['firstname', 'lastname'],
		},
		email: {
			dataType: 'string',
			title: 'E-mail',
			validation: { required: true },
		},
		image_header: {
			config: {
				storageMeta: {
					acceptedFiles: ['image/*'],
					mediaType: 'image',
					metadata: {
						cacheControl: 'max-age=1000000',
					},
					storagePath: 'locations',
				},
			},
			dataType: 'string',
			description: 'Upload header image',
			title: 'Header image',
		},
		image_map: {
			config: {
				storageMeta: {
					acceptedFiles: ['image/*'],
					mediaType: 'image',
					metadata: {
						cacheControl: 'max-age=1000000',
					},
					storagePath: 'locations',
				},
			},
			dataType: 'string',
			description: 'Upload map image',
			title: 'Map image',
		},
		image_office: {
			config: {
				storageMeta: {
					acceptedFiles: ['image/*'],
					mediaType: 'image',
					metadata: {
						cacheControl: 'max-age=1000000',
					},
					storagePath: 'locations',
				},
			},
			dataType: 'string',
			description: 'Upload office image',
			title: 'Office image',
		},
		image_stamp: {
			config: {
				storageMeta: {
					acceptedFiles: ['image/*'],
					mediaType: 'image',
					metadata: {
						cacheControl: 'max-age=1000000',
					},
					storagePath: 'locations',
				},
			},
			dataType: 'string',
			description: 'Upload stamp image',
			title: 'Stamp image',
		},
		facility_parking: {
			dataType: 'boolean',
			title: 'Facility - Parking',
		},
		facility_toilet: {
			dataType: 'boolean',
			title: 'Facility - Toilet',
		},
		facility_wheelchair_access: {
			dataType: 'boolean',
			title: 'Facility - Wheelchair access',
		},
		location_groups: {
			dataType: 'array',
			of: {
				dataType: 'reference',
				collectionPath: 'location_groups',
				previewProperties: ['name'],
			},
			title: 'Location groups',
		},
		lunch_hours_weekdays: {
			dataType: 'string',
			title: 'Lunch hours weekdays',
		},
		lunch_hours_weekends: {
			dataType: 'string',
			title: 'Lunch hours weekends',
		},
		name: {
			dataType: 'string',
			title: 'Name',
			validation: { required: true },
		},
		opening_hours_weekdays: {
			dataType: 'string',
			title: 'Opening hours weekdays',
		},
		opening_hours_weekends: {
			dataType: 'string',
			title: 'Opening hours weekends',
		},
		partner: {
			dataType: 'reference',
			title: 'Partner',
			collectionPath: 'partners',
			previewProperties: ['name'],
		},
		phone_number: {
			dataType: 'string',
			title: 'Phone number',
			validation: { required: true },
		},
		products: {
			dataType: 'array',
			of: {
				dataType: 'reference',
				collectionPath: 'products',
				previewProperties: ['brand', 'name'],
			},
			title: 'Products',
		},
		public: {
			dataType: 'boolean',
			title: 'Public',
		},
		slug: {
			dataType: 'string',
			title: 'Slug',
			validation: { required: true },
		},
		translation_key: {
			dataType: 'string',
			title: 'Translation key',
		},
		trustpilot_location_id: {
			dataType: 'string',
			title: 'Trustpilot location id',
		},
		video_office: {
			dataType: 'array',
			description: 'Upload office video',
			of: {
				config: {
					storageMeta: {
						acceptedFiles: ['video/*'],
						mediaType: 'video',
						metadata: {
							cacheControl: 'max-age=1000000',
						},
						storagePath: 'locations',
					},
				},
				dataType: 'string',
			},
			title: 'Office video',
		},
		zip_code: {
			dataType: 'string',
			title: 'Zip code',
			validation: { required: true },
		},
		created_at: {
			disabled: true,
			title: 'Created at',
			dataType: 'timestamp',
		},
		updated_at: {
			disabled: true,
			title: 'Updated at',
			dataType: 'timestamp',
		},
	},
});

const collection = buildCollection<typeof schema>({
	description: 'Locations',
	exportable: false,
	group: 'Content',
	initialSort: ['name', 'desc'],
	name: 'Locations',
	pagination: true,
	permissions: ({ authController, user }) => ({
		create: authController.extra.roles.includes('superadmin'),
		delete: authController.extra.roles.includes('superadmin'),
		edit: authController.extra.roles.includes('superadmin'),
	}),
	relativePath: 'locations',
	schema: schema,
});

export default collection;
