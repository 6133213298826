import firebase from 'firebase/app';
import 'firebase/functions';

export interface TextSearchDelegate {
	performTextSearch(query: string): Promise<readonly string[]>;
}

export function SearchDelegate(name: string): TextSearchDelegate {
		return {
			performTextSearch: (query: string): Promise<readonly string[]> => {
				const searchSearch = firebase.app().functions('europe-west1').httpsCallable('search-search');
				return searchSearch({ name: name, query: query }).then((result : any) => {
					return result.data.data;
				})
				.catch((error: any) => {
					console.log(error);
					return [];
				});
			}
	};
}