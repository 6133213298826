import { buildCollection, buildSchema } from '@camberi/firecms';

const schema = buildSchema({
	name: 'Cost',
	properties: {
		count_item: {
			dataType: 'number',
			title: 'Count item',
			validation: { integer: true, required: true },
		},
		date_start: {
			dataType: 'timestamp',
			title: 'Date start',
			validation: { required: true },
		},
		date_end: {
			dataType: 'timestamp',
			title: 'Date end',
			validation: { required: true },
		},
		product: {
			dataType: 'reference',
			title: 'Product',
			collectionPath: 'products',
			previewProperties: ['name'],
			validation: { required: true },
		},
		location: {
			dataType: 'reference',
			title: 'Location',
			collectionPath: 'locations',
			previewProperties: ['name'],
			validation: { required: true },
		},
		percentage_vat: {
			dataType: 'number',
			title: 'Percentage VAT',
			validation: { required: true },
		},
		created_at: {
			disabled: true,
			title: 'Created at',
			dataType: 'timestamp',
		},
		updated_at: {
			disabled: true,
			title: 'Updated at',
			dataType: 'timestamp',
		},
	},
});

const collection = buildCollection<typeof schema>({
	description: 'Costs',
	group: 'Economy',
	name: 'Cost',
	permissions: ({ authController, user }) => ({
		create: authController.extra.roles.includes('superadmin'),
		delete: authController.extra.roles.includes('superadmin'),
		edit: authController.extra.roles.includes('superadmin'),
	}),
	relativePath: 'costs',
	schema: schema,
});

export default collection;
