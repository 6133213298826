import { buildCollection, buildSchema } from '@camberi/firecms';
import 'firebase/functions';

const schema = buildSchema({
	customId: true,
	name: 'Tracking',
	properties: {
		active: {
			dataType: 'boolean',
			title: 'Active',
		},
		description: {
			columnWidth: 500,
			config: {
				multiline: true,
			},
			dataType: 'string',
			title: 'Description',
		},
		name: {
			dataType: 'string',
			title: 'Name',
			validation: { required: true },
		},
		created_at: {
			disabled: true,
			title: 'Created at',
			dataType: 'timestamp',
		},
		updated_at: {
			disabled: true,
			title: 'Updated at',
			dataType: 'timestamp',
		},
	},
});

const collection = buildCollection<typeof schema>({
	description: 'Tracking',
	exportable: false,
	group: 'Content',
	name: 'Tracking',
	pagination: true,
	permissions: ({ authController, user }) => ({
		create: authController.extra.roles.includes('superadmin'),
		delete: authController.extra.roles.includes('superadmin'),
		edit: authController.extra.roles.includes('superadmin'),
	}),
	properties: ['active', 'description', 'name', 'created_at', 'updated_at'],
	relativePath: 'tracking',
	schema: schema,
});

export default collection;
