import { buildCollection, buildSchema } from '@camberi/firecms';
import 'firebase/functions';

const schema = buildSchema({
	name: 'Employees',
	properties: {
		image: {
			config: {
				storageMeta: {
					acceptedFiles: ['image/*'],
					mediaType: 'image',
					metadata: {
						cacheControl: 'max-age=1000000',
					},
					storagePath: 'employees',
				},
			},
			dataType: 'string',
			description: 'Upload image',
			title: 'Image',
		},
		name: {
			dataType: 'string',
			title: 'Name',
			validation: { required: true },
		},
		created_at: {
			dataType: 'timestamp',
			disabled: true,
			title: 'Created at',
		},
		updated_at: {
			dataType: 'timestamp',
			disabled: true,
			title: 'Updated at',
		},
	},
});

const collection = buildCollection<typeof schema>({
	description: 'Employees',
	exportable: true,
	group: 'Employees',
	name: 'Employees',
	pagination: true,
	permissions: ({ authController, user }) => ({
		create: authController.extra.roles.includes('superadmin'),
		delete: authController.extra.roles.includes('superadmin'),
		edit: authController.extra.roles.includes('superadmin'),
	}),
	relativePath: 'employees',
	schema: schema,
});

export default collection;
