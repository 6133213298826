import { FormikHelpers, useFormik } from 'formik';
import {
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { SnackbarKey, useSnackbar } from 'notistack';

const Form = styled('form')(({ theme }) => ({
	marginBottom: '10px',
	marginTop: '10px',
	'& .MuiTextField-root': {
		margin: theme.spacing(1, 0),
	},
	'& .MuiFormControl-root': {
		display: 'block',
	},
}));

const CheckIn = ({ id }: { id: string }) => {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const handleSubmit = async (
		values: any,
		{ resetForm, setSubmitting }: FormikHelpers<any>
	) => {
		const url =
			'https://europe-west1-testmottagningen.cloudfunctions.net/booking-event';

		try {
			const snackbarKey = enqueueSnackbar('Check in, please wait..', {
				persist: true,
				variant: 'info',
			});

			const requestAccess = await fetch(
				`${url}?action=check_in&external_id=${values.external_id}&id=${id}&lab=${values.lab}`
			);
			const result = await requestAccess.json();
			closeSnackbar(snackbarKey);

			const status = (result as any).status;
			if (status === 'ok') {
				enqueueSnackbar('Patient checked in.', {
					autoHideDuration: 10000,
					variant: 'success',
				});
			} else {
				const codes = (result as any).data.codes;
				let message = (result as any).data.message;
				if (!message) {
					message = `Unable to check in: ${
						codes ? codes.join(', ') : 'unknown error'
					}`;
				}

				let snackbarCloseKey: SnackbarKey = '';
				const snackbarActions = () => (
					<Button onClick={() => closeSnackbar(snackbarCloseKey)}>Close</Button>
				);

				snackbarCloseKey = enqueueSnackbar(message, {
					action: snackbarActions,
					persist: true,
					variant: 'error',
				});
			}
		} catch (error) {
			console.log(error);
		}
	};

	const formik = useFormik({
		initialValues: {
			external_id: '',
			lab: 'testmottagningen',
		},
		onSubmit: handleSubmit,
	});

	return (
		<Form onSubmit={formik.handleSubmit}>
			<FormControl>
				<InputLabel id="lab">Lab</InputLabel>
				<Select
					error={formik.touched.lab && Boolean(formik.errors.lab)}
					id="lab"
					labelId="lab"
					name="lab"
					onChange={formik.handleChange}
					value={formik.values.lab}
					variant="outlined"
				>
					<MenuItem value={'testmottagningen'}>Testmottagningen</MenuItem>
					<MenuItem value={'gettested'}>Gettested</MenuItem>
					<MenuItem value={'unilabs'}>Unilabs</MenuItem>
				</Select>

				<TextField
					autoComplete="off"
					autoFocus
					error={
						formik.touched.external_id && Boolean(formik.errors.external_id)
					}
					helperText={formik.touched.external_id && formik.errors.external_id}
					id="external_id"
					onChange={formik.handleChange}
					label="External id"
					value={formik.values.external_id}
					variant="outlined"
				/>
			</FormControl>

			<Button color="primary" variant="contained" type="submit">
				Check-in patient
			</Button>
		</Form>
	);
};

export default CheckIn;
