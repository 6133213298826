import { buildCollection, buildSchema } from '@camberi/firecms';

const schema = buildSchema({
	name: 'Product',
	properties: {
		brand: {
			dataType: 'string',
			title: 'Brand',
			validation: { required: true },
		},
		name: {
			dataType: 'string',
			title: 'Name',
			validation: { required: true },
		},
		description: {
			columnWidth: 500,
			config: {
				multiline: true,
			},
			dataType: 'string',
			title: 'Description',
		},
		certification: {
			columnWidth: 300,
			config: {
				multiline: true,
			},
			dataType: 'string',
			title: 'Certification',
		},
		certification_eu_device_id: {
			dataType: 'number',
			title: 'EU device ID',
			validation: { integer: true },
		},
		image: {
			config: {
				storageMeta: {
					acceptedFiles: ['image/*'],
					mediaType: 'image',
					metadata: {
						cacheControl: 'max-age=1000000',
					},
					storagePath: 'products',
				},
			},
			dataType: 'string',
			description: 'Upload image',
			title: 'Image',
		},
		person: {
			config: {
				enumValues: {
					adult: 'Adult',
					child: 'Child',
				},
			},
			dataType: 'string',
			description: 'Person',
			title: 'Person',
			validation: { required: true },
		},
		type: {
			config: {
				enumValues: {
					antibody: 'Antibody',
					antigen: 'Antigen',
					pcr: 'PCR',
					unknown: 'Unknown',
				},
			},
			dataType: 'string',
			description: 'Type',
			title: 'Type',
			validation: { required: true },
		},
		analyze_method: {
			dataType: 'array',
			of: {
				config: {
					enumValues: {
						capillary_blood: 'Capillary blood',
						nasal: 'Nasal',
						nasopharyngeal: 'Nasopharyngeal',
						oropharyngeal: 'Oropharyngeal',
						saliva: 'Saliva',
					},
				},
				dataType: 'string',
			},
			title: 'Analyze method',
		},
		code_format: {
			config: {
				enumValues: {
					barcode: 'Barcode',
					qr: 'QR-code',
				},
			},
			dataType: 'string',
			title: 'Code format',
			validation: { required: true },
		},
		code_source: {
			config: {
				enumValues: {
					external_id: 'External id',
					patient_id: 'Patient id',
				},
			},
			dataType: 'string',
			title: 'Code source',
		},
		lab_partner: {
			config: {
				enumValues: {
					amplexa_genetics: 'Amplexa Genetics',
					express_care: 'Express Care',
					gettested: 'getTested',
					'in-house': 'In-house',
					redhot_diagnostics: 'RedHot Diagnostics',
					testcenter: 'Testcenter',
					unilabs: 'Unilabs',
				},
			},
			dataType: 'string',
			title: 'Lab partner',
			validation: { required: true },
		},
		analyze_code: {
			dataType: 'string',
			title: 'Analyze code',
		},
		created_at: {
			disabled: true,
			title: 'Created at',
			dataType: 'timestamp',
		},
		updated_at: {
			disabled: true,
			title: 'Updated at',
			dataType: 'timestamp',
		},
	},
});

const schemaPrice = buildSchema({
	name: 'Price',
	properties: {
		amount_fee: {
			dataType: 'number',
			title: 'Amount fee',
			validation: { required: true },
		},
		amount_cost: {
			dataType: 'number',
			title: 'Amount cost',
			validation: { required: true },
		},
		amount_sell: {
			dataType: 'number',
			title: 'Amount sell',
			validation: { integer: true, required: true },
		},
		date_start: {
			dataType: 'timestamp',
			title: 'Date start',
			validation: { required: true },
		},
		date_end: {
			dataType: 'timestamp',
			title: 'Date end',
			validation: { required: true },
		},
		description: {
			dataType: 'string',
			title: 'Description',
		},
		percentage_vat: {
			dataType: 'number',
			title: 'Percentage VAT',
			validation: { required: true },
		},
		type: {
			config: {
				enumValues: {
					product: 'Product',
					other: 'Other',
				},
			},
			dataType: 'string',
			description: 'Type',
			title: 'Type',
			validation: { required: true },
		},
		created_at: {
			disabled: true,
			title: 'Created at',
			dataType: 'timestamp',
		},
		updated_at: {
			disabled: true,
			title: 'Updated at',
			dataType: 'timestamp',
		},
	},
});

const collectionPrice = buildCollection<typeof schemaPrice>({
	name: 'Prices',
	relativePath: 'prices',
	schema: schemaPrice,
});

const collection = buildCollection<typeof schema>({
	description: 'Products',
	group: 'Logistics',
	initialSort: ['brand', 'asc'],
	name: 'Products',
	permissions: ({ authController, user }) => ({
		create: authController.extra.roles.includes('superadmin'),
		delete: authController.extra.roles.includes('superadmin'),
		edit: authController.extra.roles.includes('superadmin'),
	}),
	relativePath: 'products',
	schema: schema,
	subcollections: [collectionPrice],
});

export default collection;
