import { useAuthController } from '@camberi/firecms';
import { Button } from '@material-ui/core';

const PdfButton = ({
	id,
	name,
	type,
}: {
	id: string;
	name: string;
	type?: string;
}) => {
	const authController = useAuthController();

	const onClick = async (event: React.MouseEvent) => {
		authController?.loggedUser?.getIdToken().then(function (idToken: string) {
			window.open(
				`https://europe-west1-testmottagningen.cloudfunctions.net/booking-pdf?id=${id}&type=${type}&token=${idToken}`,
				'_blank'
			);
		});
	};

	return (
		<Button onClick={onClick} variant="contained">
			{name}
		</Button>
	);
};

export default PdfButton;
