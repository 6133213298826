import { buildCollection, buildSchema } from '@camberi/firecms';
import 'firebase/functions';
import { SearchDelegate } from '../lib/search';

const schema = buildSchema({
	name: 'Citizenships',
	properties: {
		name: {
			dataType: 'string',
			description: 'Must be in English',
			title: 'Name',
			validation: { required: true },
		},
		certificate_covidbevis: {
			dataType: 'boolean',
			title: 'Certificate covidbevis',
		},
		certificate_testmottagningen: {
			dataType: 'boolean',
			title: 'Certificate testmottagningen',
		},
		locale: {
			dataType: 'string',
			title: 'Locale',
		},
		translation_key: {
			dataType: 'string',
			title: 'Translation key',
		},
		trustpilot_template_id: {
			dataType: 'string',
			title: 'Trustpilot template id',
		},
		created_at: {
			dataType: 'timestamp',
			disabled: true,
			title: 'Created at',
		},
		updated_at: {
			dataType: 'timestamp',
			disabled: true,
			title: 'Updated at',
		},
	},
});

const collection = buildCollection<typeof schema>({
	description: 'Citizenships',
	exportable: true,
	group: 'Content',
	initialSort: ['name', 'asc'],
	name: 'Citizenships',
	pagination: true,
	permissions: ({ authController, user }) => ({
		create: authController.extra.roles.includes('superadmin'),
		delete: authController.extra.roles.includes('superadmin'),
		edit: authController.extra.roles.includes('superadmin'),
	}),
	relativePath: 'citizenships',
	schema: schema,
	textSearchDelegate: SearchDelegate('citizenships'),
});

export default collection;
