import { Authenticator } from '@camberi/firecms';
import firebase from 'firebase/app';

const authentication: Authenticator = async (user?: firebase.User) => {
	if (!user || !user?.emailVerified) {
		return false;
	}

	const token = await user.getIdTokenResult();
	if(!token || !token.claims || !token.claims.admin || !token.claims.partner) {
		return false;
	}

	return true;
};

export default authentication;