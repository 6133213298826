import {
	AdditionalColumnDelegate,
	AsyncPreviewComponent,
	buildCollection,
	buildSchema,
	Entity,
	EntityCustomView,
	PreviewComponentProps,
	TimestampPreview,
	TimestampProperty,
} from '@camberi/firecms';
import 'firebase/functions';
import BookingActions from '../components/booking_actions';
import CheckIn from '../components/check_in';
import { green } from '@material-ui/core/colors';
import {
	Box,
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
} from '@material-ui/core';
import { CheckBox } from '@material-ui/icons';
import PdfButton from '../components/pdf_button';
import { SearchDelegate } from '../lib/search';

const additionalColumn: AdditionalColumnDelegate = {
	builder: (entity: Entity<typeof schema>) => <BookingActions id={entity.id} />,
	id: 'actions',
	title: 'Actions',
	width: 100,
};

const checkView: EntityCustomView = {
	path: 'check',
	name: 'Check',
	builder: ({ schema, entity, modifiedValues }) => {
		if (!entity || !entity.id) {
			return <></>;
		}

		return (
			<Box m={2}>
				<Grid container direction="row">
					<Grid item xs={9}>
						<Box my={2}>
							{(!entity.values.event ||
								(entity.values.event && !entity.values.event.check_in)) && (
								<CheckIn id={entity.id} />
							)}
						</Box>

						<TableContainer component={Paper}>
							<Table aria-label="simple table">
								<TableBody>
									{entity.values.event && entity.values.event.check_in && (
										<TableRow>
											<TableCell>Checked-in</TableCell>
											<TableCell>
												<CheckBox style={{ color: green[500] }} />
											</TableCell>
										</TableRow>
									)}
									<TableRow>
										<TableCell>Patient id</TableCell>
										<TableCell>{entity.values.patient_id}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>External id</TableCell>
										<TableCell>{entity.values.external_id}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Location</TableCell>
										<TableCell>
											<AsyncPreviewComponent
												builder={entity.values.location
													.get()
													.then(
														(snapshot: any) => snapshot.get('name') as string
													)}
											/>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Product</TableCell>
										<TableCell>
											<AsyncPreviewComponent
												builder={entity.values.product
													.get()
													.then(
														(snapshot: any) => snapshot.get('name') as string
													)}
											/>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>&#160;</TableCell>
										<TableCell>&#160;</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>First name</TableCell>
										<TableCell>{entity.values.firstname}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Last name</TableCell>
										<TableCell>{entity.values.lastname}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Citizenship</TableCell>
										<TableCell>
											<AsyncPreviewComponent
												builder={entity.values.citizenship
													.get()
													.then(
														(snapshot: any) => snapshot.get('name') as string
													)}
											/>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Date of birth</TableCell>
										<TableCell>{entity.values.date_of_birth}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Gender</TableCell>
										<TableCell>{entity.values.gender}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Personal number</TableCell>
										<TableCell>{entity.values.personal_number}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Passport number</TableCell>
										<TableCell>{entity.values.passport_number}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>E-mail</TableCell>
										<TableCell>
											{entity.values.email}
											{entity.values.event &&
												entity.values.event.email_verified && (
													<span>
														&#160;
														<CheckBox style={{ color: green[500] }} />
													</span>
												)}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Phone number</TableCell>
										<TableCell>
											{entity.values.phone_number}
											{entity.values.event &&
												entity.values.event.phone_number_verified && (
													<span>
														&#160;
														<CheckBox style={{ color: green[500] }} />
													</span>
												)}
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
					<Grid item xs={3}>
						<Box display="flex" justifyContent="flex-end">
							<PdfButton id={entity.id} name="Booking PDF" />
						</Box>
						<Box display="flex" justifyContent="flex-end" my={2}>
							<PdfButton id={entity.id} name="Booking label PDF" type="label" />
						</Box>
					</Grid>
				</Grid>
			</Box>
		);
	},
};

function eventPreview({ value, property, size }: PreviewComponentProps<any>) {
	const map: any = {
		check_in: 'Checked in',
		email_verified: 'E-mail verified',
		phone_number_verified: 'Phone number verified',
	};

	const data = Object.keys(value)
		.sort()
		.map((key: string) => {
			const type = map[key] ?? key.replace(/_/g, ' ');

			if (value[key] instanceof Date) {
				return (
					<div>
						{type}:{' '}
						<TimestampPreview
							value={value[key]}
							property={property as TimestampProperty}
							size={size}
						/>
					</div>
				);
			} else if (value[key]) {
				return (
					<div>
						{type}: {value[key]}
					</div>
				);
			}
			return <></>;
		});

	return <>{data}</>;
}

const schema = buildSchema({
	name: 'Booking',
	properties: {
		address: {
			dataType: 'string',
			title: 'Address',
		},
		citizenship: {
			dataType: 'reference',
			title: 'Citizenship',
			collectionPath: 'citizenships',
			previewProperties: ['name'],
			validation: { required: true },
		},
		date_of_birth: {
			dataType: 'string',
			title: 'Date of birth',
			validation: {
				matches:
					/^((?:19|20)[0-9][0-9])[-](0?[1-9]|1[012])[-](0?[1-9]|[12][0-9]|3[01])$/,
				required: true,
			},
		},
		email: {
			dataType: 'string',
			title: 'E-mail',
			validation: { email: true, lowercase: true, required: true },
		},
		email_alternative: {
			dataType: 'string',
			title: 'E-mail (alternative)',
			validation: { lowercase: true },
		},
		external_id: {
			dataType: 'string',
			title: 'External id',
		},
		event: {
			config: {
				preview: eventPreview,
			},
			dataType: 'map',
			disabled: true,
			properties: {},
			title: 'Event',
		},
		firstname: {
			dataType: 'string',
			title: 'First name',
			validation: { required: true },
		},
		gender: {
			config: {
				enumValues: {
					female: 'Female',
					male: 'Male',
					unknown: 'Unknown',
				},
			},
			dataType: 'string',
			title: 'Gender',
		},
		lastname: {
			dataType: 'string',
			title: 'Last name',
			validation: { required: true },
		},
		location: {
			dataType: 'reference',
			title: 'Location',
			collectionPath: 'locations',
			previewProperties: ['name'],
		},
		passport_number: {
			dataType: 'string',
			title: 'Passport Number',
		},
		patient_id: {
			dataType: 'string',
			title: 'Patient id',
			validation: { required: true },
		},
		personal_number: {
			dataType: 'string',
			title: 'Personal Number',
		},
		phone_number: {
			dataType: 'string',
			title: 'Phone number',
			validation: { matches: /^\+(?:[0-9] ?){6,14}[0-9]$/, required: true },
		},
		product: {
			dataType: 'reference',
			title: 'Product',
			collectionPath: 'products',
			previewProperties: ['brand', 'name'],
		},
		reserve_number: {
			dataType: 'string',
			title: 'Reserve number',
		},
		service: {
			dataType: 'reference',
			title: 'Service',
			collectionPath: 'services',
			previewProperties: ['name'],
		},
		status: {
			config: {
				enumValues: {
					cancelled: 'Cancelled',
					completed: 'Completed',
					new: 'New',
				},
			},
			dataType: 'string',
			title: 'Status',
			validation: { required: true },
		},
		tracking: {
			collectionPath: 'tracking',
			dataType: 'reference',
			previewProperties: ['name'],
			title: 'Tracking',
		},
		type: {
			config: {
				enumValues: {
					certificate: 'Certificate',
					travel: 'Travel',
				},
			},
			dataType: 'string',
			description: 'Type of document',
			title: 'Type',
			validation: { required: true },
		},
		agreement_accepted_at: {
			disabled: true,
			title: 'Agreement accepted at',
			dataType: 'timestamp',
		},
		created_at: {
			disabled: true,
			title: 'Created at',
			dataType: 'timestamp',
		},
		updated_at: {
			disabled: true,
			title: 'Updated at',
			dataType: 'timestamp',
		},
	},
	views: [checkView],
});

const collection = buildCollection<typeof schema>({
	additionalColumns: [additionalColumn],
	description: 'Bookings',
	exportable: false,
	group: 'Admin',
	initialFilter: {
		status: ['>=', 'new'],
	},
	initialSort: ['created_at', 'desc'],
	name: 'Bookings',
	pagination: 20,
	permissions: ({ user }) => ({
		create: true,
		delete: true,
		edit: true,
	}),
	properties: [
		'actions',
		'status',
		'patient_id',
		'external_id',
		'product',
		'service',
		'location',
		'firstname',
		'lastname',
		'citizenship',
		'email',
		'email_alternative',
		'phone_number',
		'date_of_birth',
		'personal_number',
		'passport_number',
		'type',
		'address',
		'gender',
		'reserve_number',
		'tracking',
		'event',
		'agreement_accepted_at',
		'created_at',
		'updated_at',
	],
	relativePath: 'bookings',
	schema: schema,
	textSearchDelegate: SearchDelegate('bookings'),
});

export default collection;
