import { buildCollection, buildSchema } from '@camberi/firecms';
import 'firebase/functions';
import { SearchDelegate } from '../lib/search';

const schema = buildSchema({
	defaultValues: { status: 'new', type: 'tillfrisknandebevis' },
	name: 'Certificate',
	properties: {
		status: {
			config: {
				enumValues: {
					created: 'Created',
					error: 'Error',
					new: 'New',
				},
			},
			dataType: 'string',
			title: 'Status',
		},
		type: {
			config: {
				enumValues: {
					tillfrisknandebevis: 'Tillfrisknandebevis',
				},
			},
			dataType: 'string',
			title: 'Type',
		},
		patient_id: {
			dataType: 'string',
			title: 'Patient id',
		},
		personal_number: {
			dataType: 'string',
			title: 'Personal Number',
		},
		parent_personal_number: {
			dataType: 'string',
			title: 'Parent personal Number',
		},
		tested_at: {
			dataType: 'timestamp',
			title: 'Tested at',
		},
		information: {
			config: {
				multiline: true,
			},
			dataType: 'string',
			title: 'Information',
		},
		agreement_accepted_at: {
			disabled: true,
			title: 'Agreement accepted at',
			dataType: 'timestamp',
		},
		created_at: {
			dataType: 'timestamp',
			disabled: true,
			title: 'Created at',
		},
		updated_at: {
			dataType: 'timestamp',
			disabled: true,
			title: 'Updated at',
		},
	},
});

const collection = buildCollection<typeof schema>({
	description: 'Certificates',
	exportable: false,
	group: 'Admin',
	initialFilter: {
		status: ['==', 'new'],
	},
	name: 'Certificates',
	pagination: 20,
	permissions: ({ authController, user }) => ({
		create: true,
		delete: true,
		edit: true,
	}),
	relativePath: 'certificates',
	schema: schema,
	textSearchDelegate: SearchDelegate('certificates'),
});

export default collection;
