import { buildCollection, buildSchema } from '@camberi/firecms';
import 'firebase/functions';

const schema = buildSchema({
	name: 'Test',
	properties: {
		location: {
			dataType: 'reference',
			title: 'Location',
			collectionPath: 'locations',
			previewProperties: ['name'],
		},
		name: {
			dataType: 'string',
			title: 'Name',
			validation: { required: true },
		},
		created_at: {
			dataType: 'timestamp',
			disabled: true,
			title: 'Created at',
		},
		updated_at: {
			dataType: 'timestamp',
			disabled: true,
			title: 'Updated at',
		},
	},
});

const collection = buildCollection<typeof schema>({
	description: 'Test',
	exportable: true,
	group: 'Test',
	name: 'Test',
	pagination: true,
	permissions: ({ user }) => ({
		create: true,
		delete: true,
		edit: true,
	}),
	relativePath: 'test',
	schema: schema,
});

export default collection;
