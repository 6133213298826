import { buildCollection, buildSchema } from '@camberi/firecms';
import 'firebase/functions';

const schema = buildSchema({
	defaultValues: { status: 'new' },
	name: 'Contact tracing',
	properties: {
		status: {
			config: {
				enumValues: {
					done: 'Done, reported to sminet',
					pending: 'Information gathered',
					new: 'New',
					no_answer: 'Called, no answer',
				},
			},
			dataType: 'string',
			title: 'Status',
		},
		patient_id: {
			dataType: 'string',
			title: 'Patient id',
		},
		result: {
			collectionPath: 'results',
			dataType: 'reference',
			previewProperties: ['patient_id', 'firstname', 'lastname'],
			title: 'Result',
		},
		information: {
			config: {
				multiline: true,
			},
			dataType: 'string',
			title: 'Information',
		},
		created_at: {
			dataType: 'timestamp',
			disabled: true,
			title: 'Created at',
		},
		updated_at: {
			dataType: 'timestamp',
			disabled: true,
			title: 'Updated at',
		},
	},
});

const collection = buildCollection<typeof schema>({
	description: 'Contact tracing',
	exportable: false,
	group: 'Admin',
	initialFilter: {
		status: ['!=', 'done'],
	},
	name: 'Contact tracing',
	pagination: 20,
	permissions: ({ authController, user }) => ({
		create: true,
		delete: true,
		edit: true,
	}),
	relativePath: 'contact_tracings',
	schema: schema,
});

export default collection;
