import {
	AdditionalColumnDelegate,
	buildCollection,
	buildSchema,
	Entity,
	PreviewComponentProps,
	TimestampPreview,
	TimestampProperty,
} from '@camberi/firecms';
import 'firebase/functions';
import ResultActions from '../components/result_actions';
import { SearchDelegate } from '../lib/search';

const additionalColumn: AdditionalColumnDelegate = {
	builder: (entity: Entity<typeof schema>) => <ResultActions id={entity.id} />,
	id: 'actions',
	title: 'Actions',
	width: 100,
};

function randomString(letters: number) {
	const characters =
		'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	let data = '';

	for (let i = 0; i < letters; i++) {
		data += characters.charAt(Math.floor(Math.random() * characters.length));
	}

	const out = {
		[data]: {
			label: 'id',
		},
	};
	return out;
}

function timePreview({ value, property, size }: PreviewComponentProps<any>) {
	const map: any = {
		admin_covidbevis_opened_at: 'admin, covidbevis',
		admin_external_pdf_opened_at: 'admin, external',
		admin_japan_pdf_opened_at: 'admin, japan',
		admin_pdf_opened_at: 'admin, pdf',
		covidbevis_created_at: 'covidbevis',
		covidbevis_opened_at: 'covidbevis',
		covidbevis_supplier: 'covidbevis supplier',
		email_sent_at: 'email',
		external_pdf_opened_at: 'external',
		japan_pdf_opened_at: 'japan',
		pdf_opened_at: 'pdf',
		sms_sent_at: 'sms',
	};

	const data = Object.keys(value)
		.sort()
		.map((key: string) => {
			const type = map[key] ?? key.replace(/_/g, ' ');

			if (value[key] instanceof Date) {
				return (
					<div>
						{type}:{' '}
						<TimestampPreview
							value={value[key]}
							property={property as TimestampProperty}
							size={size}
						/>
					</div>
				);
			} else if (value[key]) {
				return (
					<div>
						{type}: {value[key]}
					</div>
				);
			}
			return <></>;
		});

	return <>{data}</>;
}

const schema = buildSchema({
	customId: randomString(200),
	defaultValues: { status: 'new', type: 'travel' },
	name: 'Result',
	onSaveSuccess: (props) => {
		props.schema.customId = randomString(200);
	},
	properties: {
		address: {
			dataType: 'string',
			title: 'Address',
		},
		analyze_information: {
			config: {
				multiline: true,
			},
			dataType: 'string',
			title: 'Analyze information',
		},
		analyze_method: {
			dataType: 'array',
			of: {
				config: {
					enumValues: {
						capillary_blood: 'Capillary blood',
						nasal: 'Nasal',
						nasopharyngeal: 'Nasopharyngeal',
						oropharyngeal: 'Oropharyngeal',
						saliva: 'Saliva',
					},
				},
				dataType: 'string',
			},
			title: 'Analyze method',
		},
		analyze_result: {
			config: {
				enumValues: {
					inconclusive: 'Inconclusive',
					negative: 'Negative',
					positive: 'Positive',
					value: 'Value',
				},
			},
			dataType: 'string',
			title: 'Analyze result',
		},
		analyze_value: {
			dataType: 'string',
			title: 'Analyze value',
		},
		citizenship: {
			dataType: 'reference',
			title: 'Citizenship',
			collectionPath: 'citizenships',
			previewProperties: ['name'],
			validation: { required: true },
		},
		count_test: {
			dataType: 'number',
			title: 'Count tests',
			validation: { integer: true, required: true },
		},
		date_of_birth: {
			dataType: 'string',
			title: 'Date of birth',
			validation: {
				matches:
					/^((?:19|20)[0-9][0-9])[-](0?[1-9]|1[012])[-](0?[1-9]|[12][0-9]|3[01])$/,
				required: true,
			},
		},
		doctor: {
			dataType: 'reference',
			title: 'Doctor',
			collectionPath: 'doctors',
			previewProperties: ['firstname', 'lastname'],
		},
		email: {
			dataType: 'string',
			title: 'E-mail',
			validation: { email: true, lowercase: true, required: true },
		},
		email_alternative: {
			dataType: 'string',
			title: 'E-mail (alternative)',
			validation: { lowercase: true },
		},
		external_certificates: {
			dataType: 'map',
			properties: {
				japanese: {
					config: {
						storageMeta: {
							acceptedFiles: ['application/*', 'image/*'],
							metadata: {
								cacheControl: 'max-age=1000000',
							},
							storagePath: 'result_certificates',
						},
					},
					dataType: 'string',
					title: 'Japanese',
				},
				lab: {
					config: {
						storageMeta: {
							acceptedFiles: ['application/*', 'image/*'],
							metadata: {
								cacheControl: 'max-age=1000000',
							},
							storagePath: 'result_certificates',
						},
					},
					dataType: 'string',
					title: 'Lab',
				},
			},
			title: 'External certificates',
		},
		external_id: {
			dataType: 'string',
			title: 'External id',
		},
		firstname: {
			dataType: 'string',
			title: 'First name',
			validation: { required: true },
		},
		gender: {
			config: {
				enumValues: {
					female: 'Female',
					male: 'Male',
					unknown: 'Unknown',
				},
			},
			dataType: 'string',
			title: 'Gender',
		},
		lastname: {
			dataType: 'string',
			title: 'Last name',
			validation: { required: true },
		},
		location: {
			dataType: 'reference',
			title: 'Location',
			collectionPath: 'locations',
			previewProperties: ['name'],
		},
		notify: {
			config: {
				preview: timePreview,
			},
			dataType: 'map',
			disabled: true,
			properties: {},
			title: 'Notify',
		},
		passport_number: {
			dataType: 'string',
			title: 'Passport Number',
		},
		patient_id: {
			dataType: 'string',
			title: 'Patient id',
			validation: { required: true },
		},
		personal_number: {
			dataType: 'string',
			title: 'Personal Number',
		},
		phone_number: {
			dataType: 'string',
			title: 'Phone number',
			validation: { matches: /^\+(?:[0-9] ?){6,14}[0-9]$/, required: true },
		},
		product: {
			dataType: 'reference',
			title: 'Product',
			collectionPath: 'products',
			previewProperties: ['brand', 'name'],
		},
		reserve_number: {
			dataType: 'string',
			title: 'Reserve number',
		},
		result_image: {
			config: {
				storageMeta: {
					acceptedFiles: ['application/*', 'image/*'],
					metadata: {
						cacheControl: 'max-age=1000000',
					},
					storagePath: 'results',
				},
			},
			dataType: 'string',
			description: 'Upload result image',
			title: 'Result image',
		},
		status: {
			config: {
				enumValues: {
					new: 'New',
					check: 'Check',
					reported: 'Reported',
					verified: 'Verified',
				},
			},
			dataType: 'string',
			title: 'Status',
			validation: { required: true },
		},
		status_changed_at: {
			disabled: true,
			title: 'Status changed at',
			dataType: 'timestamp',
		},
		status_report: {
			dataType: 'array',
			of: {
				config: {
					storageMeta: {
						acceptedFiles: ['application/*'],
						metadata: {
							cacheControl: 'max-age=1000000',
						},
						storagePath: 'reports',
					},
				},
				dataType: 'string',
			},
			title: 'Status report',
		},
		track: {
			config: {
				preview: timePreview,
			},
			dataType: 'map',
			disabled: true,
			properties: {},
			title: 'Track',
		},
		type: {
			config: {
				enumValues: {
					certificate: 'Certificate',
					travel: 'Travel',
				},
			},
			dataType: 'string',
			description: 'Type of document',
			title: 'Type',
			validation: { required: true },
		},
		created_at: {
			disabled: true,
			title: 'Created at',
			dataType: 'timestamp',
		},
		tested_at: {
			dataType: 'timestamp',
			title: 'Tested at',
		},
		updated_at: {
			disabled: true,
			title: 'Updated at',
			dataType: 'timestamp',
		},
	},
});

const schemaJournal = buildSchema({
	name: 'Journal',
	properties: {
		comment: {
			columnWidth: 500,
			config: {
				multiline: true,
			},
			dataType: 'string',
			title: 'Comment',
		},
		file: {
			dataType: 'array',
			of: {
				config: {
					storageMeta: {
						acceptedFiles: ['application/*'],
						metadata: {
							cacheControl: 'max-age=1000000',
						},
						storagePath: 'reports',
					},
				},
				dataType: 'string',
			},
			title: 'File(s)',
		},
		created_at: {
			disabled: true,
			title: 'Created at',
			dataType: 'timestamp',
		},
		updated_at: {
			disabled: true,
			title: 'Updated at',
			dataType: 'timestamp',
		},
	},
});

const collectionJournal = buildCollection<typeof schemaJournal>({
	name: 'Journal',
	permissions: ({ user }) => ({
		create: false,
		delete: false,
		edit: false,
	}),
	relativePath: 'journals',
	schema: schemaJournal,
});

const collection = buildCollection<typeof schema>({
	additionalColumns: [additionalColumn],
	description: 'Archive - Results',
	exportable: false,
	group: 'Archive',
	initialSort: ['created_at', 'desc'],
	name: 'Archive - Results',
	pagination: 20,
	permissions: ({ user }) => ({
		create: false,
		delete: false,
		edit: false,
	}),
	relativePath: 'archive_results',
	properties: [
		'actions',
		'status',
		'patient_id',
		'external_id',
		'firstname',
		'lastname',
		'citizenship',
		'email',
		'email_alternative',
		'phone_number',
		'date_of_birth',
		'personal_number',
		'passport_number',
		'type',
		'tested_at',
		'count_test',
		'location',
		'doctor',
		'analyze_result',
		'analyze_value',
		'analyze_method',
		'analyze_information',
		'address',
		'gender',
		'reserve_number',
		'status_changed_at',
		'result_image',
		'product',
		'external_certificates',
		'status_report',
		'created_at',
		'updated_at',
		'notify',
		'track',
	],
	schema: schema,
	subcollections: [collectionJournal],
	textSearchDelegate: SearchDelegate('archive_results'),
});

export default collection;
