import firebase from 'firebase/app';
import {
	StorageThumbnail,
	useAuthController,
	useSideEntityController,
} from '@camberi/firecms';
import 'firebase/functions';
import {
	Box,
	Button,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';

import { Check, Close, Settings } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
	button: {
		'margin-left': theme.spacing(4),
		'margin-right': theme.spacing(4),
	},
}));

function ComponentView() {
	const authController = useAuthController();
	const classes = useStyles();
	const sideEntityController = useSideEntityController();
	const { closeSnackbar, enqueueSnackbar } = useSnackbar();
	const [result, setResult] = useState<any>({});

	async function getResult() {
		try {
			const snackbarKey = enqueueSnackbar('Getting result, please wait..', {
				persist: true,
				variant: 'info',
			});

			const idToken = await authController?.loggedUser?.getIdToken();

			const resultsVerify = firebase
				.app()
				.functions('europe-west1')
				.httpsCallable('result-verify');
			const resultsData = await resultsVerify({
				token: idToken,
				type: 'control',
			});

			closeSnackbar(snackbarKey);

			const status = (resultsData as any).data.status;
			if (status === 'ok') {
				const data = (resultsData as any).data.data;
				setResult(data);
			} else {
				enqueueSnackbar(`Unable to find result`, {
					variant: 'error',
				});
			}
		} catch (error) {
			console.log(error);
		}
	}

	const onApproveClick = async (patient_id: any) => {
		try {
			const snackbarKey = enqueueSnackbar('Verifing result, please wait..', {
				persist: true,
				variant: 'info',
			});

			const idToken = await authController?.loggedUser?.getIdToken();

			const resultsVerifyAction = firebase
				.app()
				.functions('europe-west1')
				.httpsCallable('result-verify_action');
			const resultsData = await resultsVerifyAction({
				token: idToken,
				patient_id,
			});

			closeSnackbar(snackbarKey);

			const status = (resultsData as any).data.status;
			if (status === 'ok') {
				getResult();
			} else {
				enqueueSnackbar(`Unable to verify result`, {
					variant: 'error',
				});
			}
		} catch (error) {
			console.log(error);
		}
	};

	const onDenyClick = async (id: any) => {
		alert('Handle the problem!');
	};

	const onSettingsClick = async (id: any) => {
		sideEntityController.open({
			collectionPath: '/results',
			entityId: id,
		});
	};

	useEffect(() => {
		getResult();
	}, []);

	return (
		<>
			<Box
				alignItems={'center'}
				display="flex"
				flexDirection={'column'}
				justifyItems={'center'}
				m="auto"
			>
				<h3>Control</h3>

				{result && result.patient_id && (
					<Box m={2}>
						<TableContainer component={Paper}>
							<Table aria-label="simple table">
								<TableBody>
									<TableRow>
										<TableCell>Patient id</TableCell>
										<TableCell>
											{result.patient_id}{' '}
											<Button
												aria-controls="simple-menu"
												aria-haspopup="true"
												onClick={() => onSettingsClick(result.id)}
											>
												<Settings />
											</Button>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Analyze result</TableCell>
										<TableCell>{result.analyze_result}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Product</TableCell>
										<TableCell>
											{result.product && result.product.type && (
												<div>type: {result.product.type}</div>
											)}
											{result.product && result.product.brand && (
												<div>name: {result.product.brand}</div>
											)}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Analyze method</TableCell>
										<TableCell>
											{' '}
											{result.analyze_method &&
												result.analyze_method.length > 0 &&
												result.analyze_method.map(
													(analyze_method: any, j: any) => (
														<div key={j}>{analyze_method}</div>
													)
												)}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Tested at</TableCell>
										<TableCell>{result.tested_at}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell align="center" colSpan={2}>
											<StorageThumbnail
												property={{
													config: {
														url: 'image',
													},
													dataType: 'string',
												}}
												size="regular"
												value={result.result_image}
											/>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell align="center" colSpan={2}>
											<Button
												className={classes.button}
												color="secondary"
												onClick={() => onDenyClick(result.patient_id)}
												size="large"
												variant="contained"
											>
												<Close />
											</Button>
											<Button
												className={classes.button}
												color="primary"
												onClick={() => onApproveClick(result.patient_id)}
												size="large"
												variant="contained"
											>
												<Check />
											</Button>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
				)}
			</Box>
		</>
	);
}

const view = {
	description: 'Control',
	group: 'Admin',
	name: 'Control',
	path: ['control'],
	view: <ComponentView />,
};

export default view;
