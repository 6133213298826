import { buildCollection, buildSchema } from '@camberi/firecms';
import 'firebase/functions';

const schema = buildSchema({
	name: 'Inventory',
	properties: {
		checked_at: {
			dataType: 'timestamp',
			title: 'Checked at',
			validation: { required: true },
		},
		location: {
			dataType: 'reference',
			title: 'Location',
			collectionPath: 'locations',
			previewProperties: ['name'],
		},
		description: {
			config: {
				multiline: true,
			},
			dataType: 'string',
			title: 'Description',
		},
		count_antibody: {
			dataType: 'number',
			title: 'Antibody',
			validation: { integer: true, required: true },
		},
		count_antigen: {
			dataType: 'number',
			title: 'Antigen',
			validation: { integer: true, required: true },
		},
		count_capsule: {
			dataType: 'number',
			title: 'Capsules',
			validation: { integer: true, required: true },
		},
		count_egoo: {
			dataType: 'number',
			title: 'Egoos',
			validation: { integer: true, required: true },
		},
		count_golves: {
			dataType: 'number',
			title: 'Gloves',
			validation: { integer: true, required: true },
		},
		count_masks: {
			dataType: 'number',
			title: 'Masks',
			validation: { integer: true, required: true },
		},
		count_60_liter_waste_container: {
			dataType: 'number',
			description: '60 liters medical waste container',
			title: '60L waste container',
			validation: { integer: true, required: true },
		},
		count_pipette: {
			dataType: 'number',
			title: 'Pipettes',
			validation: { integer: true, required: true },
		},
		count_paper: {
			dataType: 'number',
			title: 'Paper',
			validation: { integer: true, required: true },
		},
		count_siba_buffer: {
			dataType: 'number',
			title: 'SIBA buffer',
			validation: { integer: true, required: true },
		},
		count_swab_kit: {
			dataType: 'number',
			title: 'Swab kits',
			validation: { integer: true, required: true },
		},
		serial_number_egoo: {
			config: {
				multiline: true,
			},
			dataType: 'string',
			title: 'Serial numbers - Egoo',
		},
		created_at: {
			dataType: 'timestamp',
			disabled: true,
			title: 'Created at',
		},
		updated_at: {
			dataType: 'timestamp',
			disabled: true,
			title: 'Updated at',
		},
	},
});

const collection = buildCollection<typeof schema>({
	description: 'Inventory',
	exportable: false,
	group: 'Logistics',
	initialSort: ['location', 'asc'],
	name: 'Inventory',
	pagination: true,
	permissions: ({ authController, user }) => ({
		create: authController.extra.roles.includes('superadmin'),
		delete: authController.extra.roles.includes('superadmin'),
		edit: true,
	}),
	relativePath: 'inventory',
	schema: schema,
});

export default collection;
