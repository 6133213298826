import firebase from 'firebase/app';
import { useAuthController, useSideEntityController } from '@camberi/firecms';
import 'firebase/functions';
import { green, red, yellow } from '@material-ui/core/colors';
import {
	Box,
	Button,
	FormControl,
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
} from '@material-ui/core';
import { Settings } from '@material-ui/icons';
import { useEffect, useState } from 'react';

import { CheckBox, Error, Warning } from '@material-ui/icons';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
	h3: { marginTop: '80px' },
	root: {
		marginBottom: '10px',
		marginTop: '10px',
		'& .MuiTextField-root': {
			margin: theme.spacing(1, 0),
		},
		'& .MuiAccordionDetails-root': {
			display: 'block',
		},
	},
}));

const StyledTableRow = withStyles((theme) => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

const VerifyTable = ({ data }: { data: any }) => {
	const sideEntityController = useSideEntityController();

	const onClick = async (id: any) => {
		sideEntityController.open({
			collectionPath: '/results',
			entityId: id,
		});
	};

	if (!data || !data.length) {
		return <></>;
	}

	return (
		<Grid container>
			<Grid item xs={12}>
				<TableContainer component={Paper}>
					<Table aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell>Actions</TableCell>
								<TableCell>Patient id</TableCell>
								<TableCell>Name</TableCell>
								<TableCell>Product</TableCell>
								<TableCell>Analyze method</TableCell>
								<TableCell>Tested at</TableCell>
								<TableCell>Notify</TableCell>
								<TableCell>Track</TableCell>
								<TableCell>Status</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{data.map((row: any, i: any) => (
								<StyledTableRow key={i}>
									<TableCell>
										{row.id && (
											<Button
												aria-controls="simple-menu"
												aria-haspopup="true"
												onClick={() => onClick(row.id)}
											>
												<Settings />
											</Button>
										)}
									</TableCell>
									<TableCell>{row.patient_id}</TableCell>
									<TableCell>
										{row.firstname} {row.lastname}
									</TableCell>
									<TableCell>
										{row.product && row.product.type && (
											<div>type: {row.product.type}</div>
										)}
										{row.product && row.product.brand && (
											<div>name: {row.product.brand}</div>
										)}
									</TableCell>
									<TableCell>
										{row.analyze_method &&
											row.analyze_method.length > 0 &&
											row.analyze_method.map((analyze_method: any, j: any) => (
												<div key={j}>{analyze_method}</div>
											))}
									</TableCell>
									<TableCell>{row.tested_at}</TableCell>
									<TableCell>
										{row.notify &&
											row.notify.length > 0 &&
											row.notify.map((time: any, j: any) => (
												<div key={j}>
													{time.name}: {time.value}
												</div>
											))}
									</TableCell>
									<TableCell>{row.track}</TableCell>
									<TableCell>
										<Grid container direction="row" alignItems="center">
											<Grid item>
												{row.status.type === 'error' && (
													<span>
														<Error style={{ color: red[500] }} />
														&#160;
													</span>
												)}
												{row.status.type === 'ok' && (
													<span>
														<CheckBox style={{ color: green[500] }} />
														&#160;
													</span>
												)}
												{row.status.type === 'warning' && (
													<span>
														<Warning style={{ color: yellow[500] }} />
														&#160;
													</span>
												)}
											</Grid>
											<Grid item>
												<span>{row.status.message || row.status.type}</span>
											</Grid>
										</Grid>
									</TableCell>
								</StyledTableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
		</Grid>
	);
};

function ComponentView() {
	const authController = useAuthController();
	const [check, setCheck] = useState<any[]>([]);
	const { closeSnackbar, enqueueSnackbar } = useSnackbar();
	const classes = useStyles();
	const [search, setSearch] = useState('');

	async function handleSearch(value: any) {
		if (!value) return;

		try {
			const snackbarKey = enqueueSnackbar('Getting result, please wait..', {
				persist: true,
				variant: 'info',
			});

			const idToken = await authController?.loggedUser?.getIdToken();

			const resultsVerify = firebase
				.app()
				.functions('europe-west1')
				.httpsCallable('result-verify');
			const result = await resultsVerify({ patient_id: value, token: idToken });

			closeSnackbar(snackbarKey);

			const status = (result as any).data.status;
			if (status === 'ok') {
				const data = (result as any).data.data;
				setCheck((old) => [data, ...old]);
				setSearch('');
			} else {
				const data = {
					patient_id: search,
					status: { message: 'Unable to find result', type: 'error' },
				};
				setCheck((old) => [data, ...old]);
				enqueueSnackbar(`Unable to find result with patient id: ${value}`, {
					variant: 'error',
				});
			}
		} catch (error) {
			console.log(error);
		}
	}

	async function handleSubmit(event: any) {
		event.preventDefault();
	}

	useEffect(() => {
		const delayDebounce = setTimeout(() => {
			handleSearch(search);
		}, 500);

		return () => clearTimeout(delayDebounce);
	}, [search]);

	return (
		<>
			<Box
				alignItems={'center'}
				display="flex"
				flexDirection={'column'}
				justifyItems={'center'}
				m="auto"
			>
				<h3>Verify</h3>
				<form className={classes.root} onSubmit={handleSubmit}>
					<FormControl>
						<TextField
							autoComplete="off"
							autoFocus
							id="patient_id"
							label="Patient id"
							onChange={(e) => setSearch(e.target.value)}
							value={search}
							variant="outlined"
						/>
					</FormControl>
				</form>

				<Box m={2}>
					{check && check.length > 0 && <VerifyTable data={[check[0]]} />}
					{check && check.length > 1 && (
						<>
							<h3 className={classes.h3}>History</h3>
							<VerifyTable data={check.slice(1)} />
						</>
					)}
				</Box>
			</Box>
		</>
	);
}

const view = {
	description: 'Verify',
	group: 'Admin',
	name: 'Verify',
	path: ['verify'],
	view: <ComponentView />,
};

export default view;
