import { buildCollection, buildSchema } from '@camberi/firecms';
import 'firebase/functions';

const schema = buildSchema({
	name: 'Service',
	properties: {
		active: {
			dataType: 'boolean',
			title: 'Active',
		},
		coming_soon: {
			dataType: 'boolean',
			title: 'Coming soon',
		},
		currency: {
			collectionPath: 'currencies',
			dataType: 'reference',
			title: 'Currency',
			previewProperties: ['name', 'code'],
		},
		locations: {
			dataType: 'array',
			of: {
				dataType: 'reference',
				collectionPath: 'locations',
				previewProperties: ['name'],
			},
			title: 'Locations',
		},
		name: {
			dataType: 'string',
			title: 'Name',
			validation: { required: true },
		},
		partner: {
			collectionPath: 'partners',
			dataType: 'reference',
			title: 'Partner',
			previewProperties: ['name'],
		},
		price_from: {
			dataType: 'number',
			title: 'Price from',
		},
		discount_price_from: {
			dataType: 'number',
			title: 'Discount price from',
		},
		product: {
			collectionPath: 'products',
			dataType: 'reference',
			previewProperties: ['brand', 'name'],
			title: 'Products',
			validation: { required: true },
		},
		image_information: {
			config: {
				storageMeta: {
					acceptedFiles: ['image/*'],
					mediaType: 'image',
					metadata: {
						cacheControl: 'max-age=1000000',
					},
					storagePath: 'services',
				},
			},
			dataType: 'string',
			title: 'Information image',
		},
		promotion: {
			dataType: 'boolean',
			title: 'Promotion',
		},
		service_groups: {
			dataType: 'array',
			of: {
				dataType: 'reference',
				collectionPath: 'service_groups',
				previewProperties: ['name'],
			},
			title: 'Service groups',
		},
		time_format: {
			dataType: 'string',
			title: 'Time format',
		},
		time_result: {
			dataType: 'number',
			title: 'Time result',
		},
		slug: {
			dataType: 'string',
			title: 'Slug',
		},
		translation_key: {
			dataType: 'string',
			title: 'Translation key',
		},
		created_at: {
			dataType: 'timestamp',
			disabled: true,
			title: 'Created at',
		},
		updated_at: {
			dataType: 'timestamp',
			disabled: true,
			title: 'Updated at',
		},
	},
});

const collection = buildCollection<typeof schema>({
	description: 'Services',
	exportable: false,
	group: 'Content',
	name: 'Services',
	pagination: true,
	permissions: ({ authController, user }) => ({
		create: authController.extra.roles.includes('superadmin'),
		delete: authController.extra.roles.includes('superadmin'),
		edit: authController.extra.roles.includes('superadmin'),
	}),
	relativePath: 'services',
	schema: schema,
});

export default collection;
